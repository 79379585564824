import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead, TableRow, TableCell } from '@mui/material';
import PropTypes from 'prop-types';

const EnhancedTableHead = ({ includeActions, headCells }) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
        {includeActions && <TableCell align="center">{t('common.actions')}</TableCell>}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  includeActions: PropTypes.bool,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default EnhancedTableHead;
