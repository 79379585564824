import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SuccessStory } from '../../../constants';

export default function useEditNewsSchema() {
  const { t } = useTranslation();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return yup.object({
    status: yup
      .string()
      .oneOf(Object.values(SuccessStory), t('errors.required'))
      .required(t('errors.required')),
    HeadlineAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(500, t('errors.maxLength', { maxLength: 500 }))
      .required('errors.required'),
    HeadlineEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(500, t('errors.maxLength', { maxLength: 500 }))
      .required('errors.required'),
    DescriptionAR: yup.string().required(t('errors.required')),
    DescriptionEN: yup.string().required(t('errors.required')),
    publishingDate: yup.date().min(yesterday, t('common.errorDate')),
    image: yup.mixed().transform((files) => (files.length > 0 ? files[0] : null)),
  });
}
