import { Box, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useHttpHook } from '../hooks';
// import { useAlert } from 'react-alert';

// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import { ApiMethods } from '../services/api.service';
const FileViewer = ({ item, contentId, name }) => {
  const { t } = useTranslation();
  //   const alert = useAlert();
  //   const { sendRequest } = useHttpHook();
  const [itemVideo, setVideoPreview] = useState(item || {});

  //   const handleDelete = async (id) => {
  //     try {
  //       await sendRequest({
  //         url: `api/${name}/files/${contentId}/${id}`,
  //         method: ApiMethods.DELETE,
  //       });
  //       await alert.success(t('common.deletedSuccessfully'));
  //       setVideoPreview((prevState) => prevState?.filter(({ id: pid }) => id !== pid));
  //     } catch {
  //       //
  //     }
  //   };
  return (
    <>
      <Box
        style={{
          border: '1px solid lightgrey',
          borderRadius: '5px',
          padding: '30px',
          marginTop: '30px',
        }}
        sx={{ flexGrow: 1 }}
      >
        <Grid container spacing={8}>
          <Grid sx={{ position: 'relative' }} item xs={12} key={itemVideo.id} md={12} lg={12}>
            <a
              style={{
                border: '2px solid lightgrey',
                borderRadius: '9px',
                fontSize: '13px',
                padding: '12px',
                textDecoration: 'none',
                paddingBlock: '20px',
                width: '100px',
                height: '100px',
              }}
              href={itemVideo?.url}
              target="_blank"
              rel="noreferrer"
            >
              {itemVideo?.url.split('//')[1].split('/')[3]}
            </a>
            {/* <IconButton
              onClick={() => handleDelete(itemVideo.id)}
              sx={{
                position: 'absolute',
                top: '60px',
                right: '-30px',
                transition: 'ease-in-out 0.2s',
                '&:hover': { color: 'red', transform: 'scale(1.1,1.1)' },
              }}
            >
              <HighlightOffIcon />
            </IconButton> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FileViewer;
