import Reactk, { useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import {
  CircularProgress,
  InputAdornment,
  Box,
  Typography,
  Fade,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import './map.styles.css';

const Map = ({
  lat = 26.677973730385958,
  lng = 29.567733757497873,
  zoom,
  setSelected,
  selected,
  setLocation,
  location,
}) => {
  const { t, i18n } = useTranslation();

  const cent = Object.keys(selected).length > 0 ? selected : { lat, lng };
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const onSearchTermChange = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const res = await getGeocode({ address });
    const { lat, lng } = getLatLng(res[0]);
    setSelected({ lat, lng });
    setLocation(address);
  };

  return (
    <>
      <GoogleMap zoom={zoom} center={cent} mapContainerClassName="map">
        {Object.keys(selected).length > 0 ? (
          <Marker position={selected} />
        ) : (
          <Marker position={{ lat, lng }} />
        )}
      </GoogleMap>
      <FormControl
        sx={{ width: '100%', zIndex: 999 }}
        variant="outlined"
        error={Object.keys(selected).length > 0 && !location}
      >
        <InputLabel htmlFor="outlined-adornment-password">
          {t('branches.searchLocation')}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          value={value ? value : location}
          onChange={(e) => {
            onSearchTermChange(e);
            setLocation(e.target.value);
          }}
          disabled={!ready}
          endAdornment={
            <InputAdornment position="end">
              {!ready ? (
                <CircularProgress size={20} />
              ) : (
                <FeatherIcon icon={'search'} width="20" height="20" />
              )}
            </InputAdornment>
          }
          label={t('branches.searchLocation')}
        />
        <FormHelperText error={Object.keys(selected).length > 0 && !location}>
          {Object.keys(selected).length > 0 && !location && t('errors.required')}
        </FormHelperText>
      </FormControl>
      <div style={{ position: 'relative' }}>
        <Fade dir="up" in={status === 'OK' || status === 'ZERO_RESULTS'}>
          <Box
            sx={{
              display: 'absolute',
              bottom: 0,
              left: 0,
              height: 'auto',
              boxShadow: 15,
              padding: 2,
              borderRadius: 3,
              marginTop: 2,
            }}
          >
            <Typography variant="h3">Results:</Typography>

            {status === 'ZERO_RESULTS' ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0.8,
                }}
              >
                <FeatherIcon icon={'frown'} width="20" height="20" />
                <Typography>No Results Found</Typography>
              </Box>
            ) : (
              data.map((item) => (
                <Typography
                  sx={{
                    opacity: 0.8,
                    transition: 'ease-in-out .2s',
                    cursor: 'pointer',
                    paddingBlock: 1,
                    '&:hover': {
                      background: 'rgba(0,0,0,0.2)',
                    },
                  }}
                  onClick={() => handleSelect(item.description)}
                  key={item?.place_id}
                >
                  {item?.description}
                </Typography>
              ))
            )}
          </Box>
        </Fade>
      </div>
    </>
  );
};

export default Map;
