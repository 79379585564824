import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, CircularProgress, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router';
import PageContainer from '../../components/container/PageContainer';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import ApiConfig from '../../config/api.config';
import { useAuthentication } from '../../hooks';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useAddContentSchema from './_hooks/useAddContentSchema';
import useContnetItem from './_hooks/useContnetItem';
import axios from 'axios';
import useContent from './_hooks/useContent';

const EditContnetItem = () => {
  const { contentId } = useParams();
  const navigate = useNavigate();

  const { tokenApi } = useAuthentication();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = React.useState([]);

  const schem = useAddContentSchema();
  const { contentItem } = useContnetItem(contentId);
  const { t } = useTranslation();
  const { contentTypes, isLoading } = useContent();
  console.log('content type', contentTypes);

  const bCrumb = [{ to: '/', title: t('home.homepage') }, { title: t('content.editContnet') }];
  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schem),
    defaultValues: {
      HeadlineAR: contentItem?.title?.ar,
      HeadlineAR: contentItem?.title?.en,

      contentType: contentTypes?.slug,
    },
  });
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  } else if (!contentItem) {
    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      let formData = new FormData();

      if (imageFile.length >= 1) {
        imageFile.forEach((img, index) => {
          formData.append(`image_array[${index}]`, img);
        });
      }
      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });
      console.log(headline)
      formData.append('title', headline);
      formData.append('description', description);
      if(form.image) {
        formData.append('image', form.image);
      }
      formData.append('group_id', form.types);

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/contents/items/${contentId}?_method=put `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/content/contentlist');
    } catch (err) {
      alert?.error(err?.response?.data?.data?.message);
      setLoading(false);
    }
  };
  return (
    <PageContainer title={t('content.editContnet')}>
      <Breadcrumb title={t('content.editContnet')} items={bCrumb} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
            defaultValue={contentItem?.title?.ar}
          />
          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue={contentItem?.description?.ar}
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            errors={errors}
            defaultValue={contentItem?.title?.en}
          />
          <CustomTextEditor
            defaultValue={contentItem?.description?.en}
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
          />
          {/* General Translation */}

          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>
          <CustomInput
            id="currentImage"
            label={t('common.currentImage')}
            errors={errors}
            {...register('currentImage')}
            defaultValue={contentItem?.image?.url}
            disabled
          />
          <CustomInput
            id="image"
            label={t('common.mainImage')}
            type="file"
            errors={errors}
            accept="image/png,image/jpeg,image/jpg"
            {...register('image')}
          />
          <CustomSelect
            id="types"
            label={t('content.title')}
            control={control}
            errors={errors}
            defaultValue={contentItem?.group_id?.id}
          >
            {contentTypes?.map((item) => (
              <MenuItem key={item?.value} value={item?.id} defaultValue={contentItem?.group_id?.id}>
                {item?.slug}
              </MenuItem>
            ))}
          </CustomSelect>
          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default EditContnetItem;
