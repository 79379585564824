import { Box, Card, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useEditSupplierSchema from './_hooks/useEditSupplierSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import axios from 'axios';
import { SupplierStatus } from '../../constants';
import useSupplier from './_hooks/useSupplier';
import ApiConfig from '../../config/api.config';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import { Navigate, useNavigate, useParams } from 'react-router';
import Spinner from '../spinner/Spinner';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import AttachView from '../../components/AttachView';
import { useAuthentication } from '../../hooks';

const EditSupplier = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { supplierId } = useParams();
  const { supplier, isLoading: isSupplierLoading } = useSupplier(supplierId);
  const { tokenApi } = useAuthentication();

  const pageTitle = t('supplier.editSupplier');
  const schema = useEditSupplierSchema();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const {
    formState: { errors },
    register,
    handleSubmit,

    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/supplier',
      title: t('supplier.name'),
    },

    {
      title: pageTitle,
    },
  ];
  console.log(errors);
  const onSubmit = async (form) => {
    try {
      setLoading(true);

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/suppliers/${supplierId}?_method=put  `,
        headers: { Authorization: 'Bearer ' + tokenApi },

        data: {
          status: form.status,
        },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/supplier');
    } catch (err) {
      Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (isSupplierLoading || !supplier) {
    return <Spinner />;
  }
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            id="LastName"
            label={t('common.companyName')}
            {...register('LastName')}
            errors={errors}
            defaultValue={supplier?.company_name}
            disabled
          />
          <CustomInput
            id="PhoneNumber"
            label={t('common.phoneNumber')}
            {...register('PhoneNumber')}
            errors={errors}
            defaultValue={supplier?.phone}
            disabled
          />
          <CustomInput
            id="Contact"
            label={t('common.contact')}
            {...register('Contact')}
            errors={errors}
            defaultValue={supplier?.point_of_contact}
            disabled
          />
          <CustomInput
            id="specialization"
            label={t('common.specialization')}
            {...register('specialization')}
            errors={errors}
            defaultValue={supplier?.specialization}
            disabled
          />
          <Typography sx={{ marginTop: 2 }} variant="h6">
            {t('common.attach')}
          </Typography>
          <AttachView item={supplier?.attachments} />

          <CustomSelect
            id="status"
            label={t('common.status')}
            control={control}
            defaultValue={supplier?.status}
          >
            {Object.keys(SupplierStatus).map((key) => (
              <MenuItem key={key} value={SupplierStatus[key]}>
                {key}
              </MenuItem>
            ))}
          </CustomSelect>

          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default EditSupplier;
