import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export default function useEditResearchSchema() {
  const { t } = useTranslation();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return yup.object({
    HeadlineAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    HeadlineEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    DescriptionAR: yup.string().required(t('errors.required')),
    DescriptionEN: yup.string().required(t('errors.required')),
    isActive: yup.bool().default(false).required(t('errors.required')),

    eventDate: yup.date(),
  });
}
