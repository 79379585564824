export const Roles = Object.freeze({
  SuperAdmin: 'SuperAdmin',
  Admin: 'Admin',
  NormalUser: 'NormalUser',
  DataEntry: 'DataEntry',
  Tester: 'Tester',
  Marketer: 'Marketer',
});
export const SuccessStory = Object.freeze({
  Active: 'active',
  UnderReview: 'underreview',
  InActive: 'inactive',
});

export const DelegateStatus = Object.freeze({
  New: 'New',
  Contacted: 'Contacted',
  Collected: 'Collected',
});
export const ApplicantStatus = Object.freeze({
  New: 'new',
  Contacted: 'contacted',
  Accepted: 'accepted',
  Rejected: 'rejected',
});
export const SupplierStatus = Object.freeze({
  New: 'new',
  Contacted: 'contacted',
  Collected: 'collected',
  Accepted: 'accepted',
  Rejected: 'rejected',
});
export const Sources = Object.freeze({
  AlMasry: 0,
  Other: 1,
});
export const Commite = Object.freeze({
  Trustee: 1,
  Wise: 2,
});
export const Payment = Object.freeze({
  Ewallets: 1,
  Banks: 2,
});
