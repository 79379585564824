import { Box, Card, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm, Controller } from 'react-hook-form';
import Map from '../../components/map/map';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useEditProjectSchema from './_hooks/useEditProjectSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import { sectors } from '../../constants';
import VideoView from '../../components/VideoView';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import useProject from './_hooks/useProject';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import ErrorMessage from '../../components/ErrorMessage';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import i18next from 'i18next';
import ReportUpload from '../../components/ReportUpload';
import CustomSwitch from '../../components/forms/custom-elements/CustomSwitch';
import useSingleSector from '../../hooks/useSingleSector';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import { Navigate, useNavigate, useParams } from 'react-router';
import Spinner from '../spinner/Spinner';
import { useAuthentication } from '../../hooks';

const defaultValues = {
  eventDate: new Date(),
};

const EditProject = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const pageTitle = t('project.editproject');
  const schema = useEditProjectSchema();
  const { tokenApi } = useAuthentication();
  const { projects, isLoading: isProjectsLoading } = useProject(projectId);
  const [report, setReport] = useState([]);
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedGeo, setSelectedGeo] = useState({});
  const [location, setLocation] = useState(null);
  const alert = useAlert();
  let { sectors } = useSingleSector();
  sectors = sectors?.data?.map((cat) =>
    i18next.language === 'en'
      ? {
          label: cat?.title?.en,
          value: cat?.id,
        }
      : {
          label: cat?.title?.ar,
          value: cat?.id,
        },
  );
  const {
    formState: { errors },
    register,
    watch,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/project',
      title: t('project.name'),
    },
    {
      title: pageTitle,
    },
  ];
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      if (report.length >= 1) {
        report.forEach((img, index) => {
          formData.append(`reports[${index}]`, img);
        });
      }

      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });
      const purpose = JSON.stringify({
        en: form.PurposeEN,
        ar: form.purposeAR,
      });
      const funding = JSON.stringify({
        en: form.FundingEN,
        ar: form.FundingAR,
      });
      const preparation = JSON.stringify({
        en: form.PreparationEN,
        ar: form.preparationAR,
      });

      formData.append('project_location', location || projects?.projects?.location);
      formData.append('latitude', selectedGeo.lat);
      formData.append('longitude', selectedGeo.lng);

      formData.append('title', headline);
      formData.append('description', description);
      formData.append('purpose', purpose);
      formData.append('funding', funding);
      formData.append('preparation', preparation);

      formData.append('field', form.sectors);
      formData.append('duration', form.duration);
      formData.append('status', form.isActive === true ? 1 : 0);
      formData.append('start_date', date || projects?.projects?.start_date);
      if (form.image) {
        formData.append('main_image', form.image);
      }

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/projects/${projectId}?_method=put`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/project');
    } catch (err) {
      Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };

  if (isProjectsLoading || !projects) {
    return <Spinner />;
  }
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
            defaultValue={projects?.projects?.title?.ar}
          />
          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue={projects?.projects?.description?.ar}
          />
          <CustomInput
            id="purposeAR"
            label={t('project.purpose')}
            {...register('purposeAR')}
            errors={errors}
            defaultValue={projects?.projects?.purpose?.ar}
          />
          <CustomInput
            id="FundingAR"
            label={t('project.funding')}
            {...register('FundingAR')}
            defaultValue={projects?.projects?.funding?.ar}
            errors={errors}
          />
          <CustomInput
            id="preparationAR"
            label={t('project.preparation')}
            {...register('preparationAR')}
            errors={errors}
            defaultValue={projects?.projects?.preparation?.ar}
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            defaultValue={projects?.projects?.title?.en}
            errors={errors}
          />
          <CustomTextEditor
            defaultValue={projects?.projects?.description?.en}
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
          />
          <CustomInput
            id="PurposeEN"
            label={t('project.purpose')}
            {...register('PurposeEN')}
            errors={errors}
            defaultValue={projects?.projects?.purpose?.en}
          />
          <CustomInput
            id="FundingEN"
            label={t('project.funding')}
            {...register('FundingEN')}
            errors={errors}
            defaultValue={projects?.projects?.funding?.en}
          />
          <CustomInput
            id="PreparationEN"
            label={t('project.preparation')}
            {...register('PreparationEN')}
            errors={errors}
            defaultValue={projects?.projects?.preparation?.en}
          />
          {/* General Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>
          <CustomInput
            id="currentImage"
            label={t('common.currentImage')}
            errors={errors}
            {...register('currentImage')}
            defaultValue={projects?.projects?.main_image?.url}
            disabled
          />
          <CustomInput
            id="image"
            label={t('common.mainImage')}
            type="file"
            errors={errors}
            accept="image/png,image/jpeg,image/jpg"
            {...register('image')}
          />{' '}
          <Typography sx={{ marginTop: 2 }} variant="h6">
            {t('common.currentReports')}
          </Typography>
          <VideoView item={projects?.projects?.reports} contentId={projectId} name="projects" />
          <CustomFormLabel>{t('common.reports')}</CustomFormLabel>
          <ReportUpload setReport={setReport} report={report} />
          <CustomInput
            errors={errors}
            id="duration"
            {...register('duration')}
            label={t('common.duration')}
            type="number"
            step=".01"
            defaultValue={projects?.projects?.duration}
          />
          <CustomSelect
            id="sectors"
            label={t('common.sectors')}
            errors={errors}
            control={control}
            defaultValue={projects?.projects?.field?.id}
          >
            {sectors?.map((item) => (
              <MenuItem key={item?.value} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </CustomSelect>
          <CustomSwitch
            id="isActive"
            label={t('common.active')}
            control={control}
            defaultValue={projects?.projects?.status}
          />
          <Box sx={{ mt: 3 }}>
            <Controller
              control={control}
              name="eventDate"
              render={() => (
                <LocalizationProvider dateAdapter={DateFnsAdapter}>
                  <DatePicker
                    label={t('common.date')}
                    fullWidth
                    onChange={(_date) => {
                      setDate(_date);
                    }}
                    value={date || projects?.projects?.start_date}
                    renderInput={(params) => (
                      <TextField {...params} error={!isUndefined(errors.eventDate)} />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Box>
          <ErrorMessage error={errors.eventDate} msg={errors.eventDate?.message} />
          <Map
            lat={projects?.projects?.latitude}
            lng={projects?.projects?.longitude}
            zoom={10}
            setSelected={setSelectedGeo}
            selected={selectedGeo}
            setLocation={setLocation}
            location={projects?.projects?.project_location}
          />
          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default EditProject;
