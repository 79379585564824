import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHttpHook } from '../../../hooks';

const useContnetItem = (itemId) => {
  const { sendRequest, isLoading } = useHttpHook();
  const [contentItem, setContentItem] = useState(null);
  useEffect(() => {
    console.log(itemId);
    const fetchContentTypes = () =>
      sendRequest({
        url: `api/contents/items/${itemId}`,
      });

    (async () => {
      const response = await fetchContentTypes();
      if (response?.tr) {
        const { id, ...rest } = response?.tr;
        Object.assign(response, rest);
      }
      setContentItem(response);
    })();
  }, []);

  return {
    isLoading,
    contentItem,
  };
};

export default useContnetItem;
