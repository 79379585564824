import { Box, Card, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import { SketchPicker } from 'react-color';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useEditSectorsSchema from './_hooks/useEditSectorsSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import CustomSwitch from '../../components/forms/custom-elements/CustomSwitch';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import useSectors from './_hooks/useSectors';
import { Navigate, useNavigate, useParams } from 'react-router';
import Spinner from '../spinner/Spinner';
import { useAuthentication } from '../../hooks';

const EditSectors = () => {
  const { t } = useTranslation();
  const { sectorId } = useParams();
  const navigate = useNavigate();

  const { tokenApi } = useAuthentication();

  const pageTitle = t('sectors.sectors');
  const { sectors, isLoading: isSectorsLoading } = useSectors(sectorId);
  const schema = useEditSectorsSchema();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const [clr, setColor] = useState();

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/sectors',
      title: t('sectors.name'),
    },
    {
      title: pageTitle,
    },
  ];

  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });
      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      formData.append('title', headline);

      formData.append('description', description);
      formData.append('status', form.isActive === true ? 1 : 0);
      if (form.image) {
        formData.append('icon', form.image);
      }
      formData.append(
        'color',
        clr ? `rgba(${clr?.rgb?.r},${clr?.rgb?.g},${clr?.rgb?.b},${clr?.rgb?.a})` : sectors?.color,
      );

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/fields/${sectorId}?_method=put  `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/sectors');
    } catch (err) {
      Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (isSectorsLoading || !sectors) {
    return <Spinner />;
  }
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
            defaultValue={sectors?.title?.ar}
          />

          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue={sectors?.description?.ar}
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            errors={errors}
            defaultValue={sectors?.title?.en}
          />

          <CustomTextEditor
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue={sectors?.description?.en}
          />
          {/* General Translation */}

          <Typography sx={{ marginTop: 2, paddingBottom: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>
          <Typography variant="h5">{t('common.color')}</Typography>
          <Box sx={{ marginTop: 2, paddingBottom: 2 }}>
            <div
              style={{
                backgroundColor: sectors?.color,
                width: '100px',
                height: '100px',
                borderRadius: '50%',
              }}
            ></div>
          </Box>
          <SketchPicker id="color" name="color" color={clr} onChange={(color) => setColor(color)} />
          <Box sx={{ mt: 4 }}>
            <CustomInput
              id="currentImage"
              label={t('common.currentImage')}
              errors={errors}
              {...register('currentImage')}
              defaultValue={sectors?.icon?.url}
              disabled
            />
            <CustomInput
              id="image"
              label={t('common.mainImage')}
              type="file"
              errors={errors}
              accept="image/png,image/jpeg,image/jpg"
              {...register('image')}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <CustomSwitch
              sx={{ mt: 61 }}
              id="isActive"
              label={t('common.active')}
              control={control}
              defaultValue={sectors?.status}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default EditSectors;
