import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, MenuItem, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router';
import PageContainer from '../../components/container/PageContainer';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import ApiConfig from '../../config/api.config';
import { Payment } from '../../constants';
import { useAuthentication } from '../../hooks';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import usePaymentSchema from './_hooks/usePaymentSchema';

const AddPayment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { tokenApi, isAuthorized } = useAuthentication();
  const [loading, setLoading] = React.useState(false);
  const [imageFile, setImageFile] = React.useState([]);
  const schema = usePaymentSchema();
  const alert = useAlert();

  const bCrumb = [{ to: '/', title: t('home.homepage') }, { title: t('payment.addMethod') }];
  const defaultValues = {
    categories: [],
    // status: SuccessStory.InActive,
    type: Payment.Ewallets,
  };
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      if (imageFile.length >= 1) {
        imageFile.forEach((img, index) => {
          formData.append(`image_array[${index}]`, img);
        });
      }
      const category = JSON.stringify(form.categories.map((cat) => cat.value));

      if (category !== '[]') {
        formData.append('categories', category);
      }
      const field = JSON.stringify([form.sectors]);
      formData.append('field', field);

      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });
      formData.append('name', headline);
      formData.append('description', description);
      formData.append('main_image', form.image);
      formData.append('type', form.type);
      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/payments `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      }).then(() => {
        navigate('/payment/paymentlist');
        alert.success(t('common.addedSuccessfully'));
        setLoading(false);
      });
    } catch (err) {
      console.log(err.data);
      // alert?.error(err?.response?.data?.data?.message);
      // Object.values(err?.response?.data?.data)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <div>
      <PageContainer title={t('payment.addMethod')}>
        <Breadcrumb title={t('payment.addMethod')} items={bCrumb} />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Arabic Translation */}
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.arabicSection')}
            </Typography>
            <CustomInput
              id="HeadlineAR"
              label={t('successStory.headline')}
              {...register('HeadlineAR')}
              errors={errors}
            />
            <CustomTextEditor
              id="DescriptionAR"
              label={t('successStory.description')}
              control={control}
              errors={errors}
              defaultValue=""
            />
            {/* English Translation */}
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.englishSection')}
            </Typography>
            <CustomInput
              id="HeadlineEN"
              label={t('successStory.headline')}
              {...register('HeadlineEN')}
              errors={errors}
            />
            <CustomTextEditor
              defaultValue=""
              id="DescriptionEN"
              label={t('successStory.description')}
              control={control}
              errors={errors}
            />
            {/* General Translation */}

            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.generalSection')}
            </Typography>
            <CustomInput
              id="image"
              label={t('common.mainImage')}
              type="file"
              errors={errors}
              accept="image/png,image/jpeg,image/jpg"
              {...register('image')}
            />
            <CustomSelect id="type" label={t('common.type')} control={control} defaultValue={[]}>
              {Object.keys(Payment).map((key) => (
                <MenuItem key={key} value={Payment[key]}>
                  {key}
                </MenuItem>
              ))}
            </CustomSelect>

            <Box sx={{ mt: 2 }}>
              <SubmitButton isLoading={loading} />
            </Box>
          </form>
        </Card>
      </PageContainer>
    </div>
  );
};

export default AddPayment;
