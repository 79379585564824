import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export default function useEditProjectSchema() {
  const { t } = useTranslation();

  return yup.object({
    isActive: yup.bool().default(false).required(t('errors.required')),

    HeadlineAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    HeadlineEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    PurposeEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    purposeAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    FundingEN: yup
      .string()
      .nullable()
      .transform((o, c) => (o === '' ? null : c))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .max(100, t('errors.maxLength', { maxLength: 100 })),

    FundingAR: yup
      .string()
      .nullable()
      .transform((o, c) => (o === '' ? null : c))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .max(100, t('errors.maxLength', { maxLength: 100 })),
    PreparationEN: yup
      .string()
      .nullable()
      .transform((o, c) => (o === '' ? null : c))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .max(100, t('errors.maxLength', { maxLength: 100 })),

    preparationAR: yup
      .string()
      .nullable()
      .transform((o, c) => (o === '' ? null : c))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .max(100, t('errors.maxLength', { maxLength: 100 })),
    DescriptionAR: yup.string().required(t('errors.required')),
    DescriptionEN: yup.string().required(t('errors.required')),
    sectors: yup.string().required(t('errors.required')),

    duration: yup
      .number()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? -1 : value))
      .min(0, t('errors.minValue', { minValue: 0 })),

    image: yup.mixed().transform((files) => (files.length > 0 ? files[0] : null)),
  });
}
