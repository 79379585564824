import { useEffect, useState } from 'react';
import { useHttpHook } from '../../../hooks';

export default function useCareer(careerId) {
  const { sendRequest, isLoading } = useHttpHook();
  const [career, setCareer] = useState();

  useEffect(() => {
    const fetchCategory = () =>
      sendRequest({
        url: `api/careers/${careerId}`,
      });

    (async () => {
      const response = await fetchCategory();
      if (response?.tr) {
        const { id, ...rest } = response?.tr;
        Object.assign(response, rest);
      }
      setCareer(response);
    })();
  }, []);
  return {
    isLoading,
    career,
  };
}
