import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useAuthentication, useHttpHook } from '../../hooks';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomPaginatedTable from '../../components/tables/CustomPaginatedTable';
import RowEditButton from '../../components/tables/RowEditButton';
import { ApiMethods } from '../../services/api.service';
import i18next from 'i18next';
import useConfirmationDialoge from '../../hooks/useConfirmationDialoge';
import { Navigate } from 'react-router';

const News = () => {
  const [news, setNews] = useState([]);
  const { t } = useTranslation();
  const { tokenApi } = useAuthentication();

  const { sendRequest } = useHttpHook();
  const { BootstrapDialog, BootstrapDialogTitle } = useConfirmationDialoge();
  const [newsId, setNewsId] = useState();
  const [open, setOpen] = React.useState(false);
  const alert = useAlert();

  const BCrumb = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      title: t('news.newsListing'),
    },
  ];
  const headCells = [
    {
      id: 'NewsId',
      label: t('common.id'),
    },
    {
      id: 'NewsHeadline',
      label: t('common.headline'),
    },
    {
      id: 'NewsStatus',
      label: t('common.status'),
    },
    {
      id: 'NewsLastUpdateTimestamp',
      label: t('common.lastUpdateTime'),
    },
  ];

  const handleDelete = async (id) => {
    try {
      await sendRequest({
        url: `api/news/${id}`,
        method: ApiMethods.DELETE,
      });
      await alert.success(t('common.deletedSuccessfully'));
      setNews((prevState) => prevState?.filter(({ id: pid }) => id !== pid));
      setOpen(false);
    } catch {
      //
    }
  };
  const handleClickOpen = (id) => {
    setNewsId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <Box>
      <PageContainer title={t('news.newsListing')} description={t('news.bundlesListDesc')}>
        <Breadcrumb title={t('news.newsListing')} items={BCrumb} />
        <CustomPaginatedTable
          headCells={headCells}
          includeActions
          name="news"
          responseKey="news"
          setEntities={setNews}
          url="api/news"
        >
          {news?.map(({ id, head_line, publish_date, status }) => {
            return (
              <TableRow key={id}>
                <TableCell align="center" component="th" id={id} scope="row" padding="none">
                  {id}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" fontWeight="600">
                    {i18next.language === 'en' ? head_line.en : head_line.ar}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Box
                      sx={{
                        backgroundColor:
                          status === 'active'
                            ? (theme) => theme.palette.success.main
                            : (theme) => theme.palette.error.main,
                        borderRadius: '100%',
                        height: '10px',
                        width: '10px',
                      }}
                    />
                    <TableCell align="center">
                      <Typography variant="h6" fontWeight="600">
                        {status}
                      </Typography>
                    </TableCell>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color="textSecondary"
                    sx={{
                      fontSize: 'h6.fontSize',
                    }}
                  >
                    {publish_date}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <RowEditButton url={`/news/edit/${id}`} />

                  <Tooltip title={t('common.delete')}>
                    <IconButton onClick={() => handleClickOpen(id)}>
                      <FeatherIcon icon="trash" width="18" />
                    </IconButton>
                  </Tooltip>
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                      {t('common.deleteConfirmation')}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                      <Typography gutterBottom> {t('common.confirmation')}</Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => handleDelete(newsId)}>{t('common.ok')}</Button>
                      <Button onClick={handleClose}>{t('common.cancel')}</Button>
                    </DialogActions>
                  </BootstrapDialog>
                </TableCell>
              </TableRow>
            );
          })}
        </CustomPaginatedTable>
      </PageContainer>
    </Box>
  );
};

export default News;
