import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
export default function useAddCategorySchema() {
  const { t } = useTranslation();

  return yup.object({
    nameAR: yup
      .string()
      .trim()
      .max(50000, t('errors.maxLength', { maxLength: 50000 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    nameEN: yup
      .string()
      .trim()
      .max(50000, t('errors.maxLength', { maxLength: 50000 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    titleAR: yup
      .string()
      .trim()
      .max(50000, t('errors.maxLength', { maxLength: 50000 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    titleEN: yup
      .string()
      .trim()
      .max(50000, t('errors.maxLength', { maxLength: 50000 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    descriptionEN: yup
      .string()
      .trim()
      .max(50000, t('errors.maxLength', { maxLength: 50000 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    descriptionAR: yup
      .string()
      .trim()
      .max(50000, t('errors.maxLength', { maxLength: 50000 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),

    image: yup.mixed().transform((files) => (files.length > 0 ? files[0] : null)),
  });
}
