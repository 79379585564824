import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
const useBanksSchema = (isEdit = false) => {
  const { t } = useTranslation();
  return yup.object({
    HeadlineAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(500, t('errors.maxLength', { maxLength: 500 })),
    HeadlineEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(500, t('errors.maxLength', { maxLength: 500 })),
    IBAN: yup.number(),
    swift_code: yup.string(),
  });
};

export default useBanksSchema;
