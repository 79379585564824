import { Box, Card, Divider, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm, Controller } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import { useAuthentication, useCategories } from '../../hooks';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useEditNewsSchema from './_hooks/useEditNewsSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import FileUpload from '../../components/FileUpload';
import VideoUpload from '../../components/VideoUpload';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import { SuccessStory } from '../../constants';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import ErrorMessage from '../../components/ErrorMessage';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import useNews from './_hooks/useNews';
import { Navigate, useNavigate, useParams } from 'react-router';
import Spinner from '../spinner/Spinner';
import ImageView from '../../components/ImageView';
import VideoView from '../../components/VideoView';
import CustomAutoComplete from '../../components/forms/custom-elements/CustomAutoComplete';
import i18next from 'i18next';
import useSingleSector from '../../hooks/useSingleSector';

const EditNews = () => {
  const { newsId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageTitle = t('news.editNews');
  const schema = useEditNewsSchema();
  const [imageFile, setImageFile] = useState([]);
  const { news, isLoading: isNewsLoading } = useNews(newsId);
  const [videoFile, setVideoFile] = useState([]);
  const { tokenApi } = useAuthentication();

  const [date, setDate] = useState();
  const [loading, setLoading] = useState(false);
  const defaultValues = { publishingDate: news?.news?.publish_date };
  const alert = useAlert();
  const defValues = [];
  let { categories } = useCategories();
  categories = categories?.data?.map((cat) =>
    i18next.language === 'en'
      ? {
          label: cat.name.en,
          value: cat.id,
        }
      : {
          label: cat.name.ar,
          value: cat.id,
        },
  );
  let { sectors } = useSingleSector();
  sectors = sectors?.data?.map((cat) =>
    i18next.language === 'en'
      ? {
          label: cat?.title?.en,
          value: cat?.id,
        }
      : {
          label: cat?.title?.ar,
          value: cat?.id,
        },
  );
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/news',
      title: t('news.name'),
    },
    {
      title: pageTitle,
    },
  ];

  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      imageFile.forEach((img, index) => {
        console.log('dd');
        formData.append(`images[${index}]`, img);
      });

      videoFile.forEach((vid, index) => {
        formData.append(`videos[${index}]`, vid);
      });

      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });
      const category = JSON.stringify(form.categories.map((cat) => cat.value));

      formData.append('categories', category);
      const field = JSON.stringify([form.sectors]);
      formData.append('field', field);
      formData.append('head_line', headline);
      formData.append('description', description);
      formData.append('status', form.status);
      formData.append('publish_date', date || news?.news?.publish_date);
      if (form.image) {
        formData.append('main_image', form.image);
      }

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/news/${newsId}?_method=put `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/news');
    } catch (err) {
      console.log(err.response);
      Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (isNewsLoading || !news) {
    return <Spinner />;
  }
  if (defValues.length === 0) {
    news?.news?.categories.map((cat) => {
      return i18next.language === 'en'
        ? defValues.push({ label: cat?.name.en, value: cat?.id })
        : defValues.push({ label: cat?.name.ar, value: cat?.id });
    });
  }
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
            defaultValue={news?.news?.head_line?.ar}
          />
          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue={news?.news?.description?.ar}
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            errors={errors}
            defaultValue={news?.news?.head_line?.en}
          />
          <CustomTextEditor
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue={news?.news?.description?.en}
          />
          {/* General Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>
          <CustomInput
            id="currentImage"
            label={t('common.currentImage')}
            errors={errors}
            {...register('currentImage')}
            defaultValue={news?.news?.main_image?.url}
            disabled
          />
          <CustomInput
            id="image"
            label={t('common.mainImage')}
            type="file"
            errors={errors}
            accept="image/png,image/jpeg,image/jpg"
            {...register('image')}
          />
          <Divider />
          <Typography sx={{ marginTop: 2 }} variant="h6">
            {t('common.currentGallery')}
          </Typography>
          <ImageView item={news?.news?.image_array} contentId={newsId} name="news" />
          {/* <Box>
            <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
              {news?.image_array.map((item) => (
                <ImageListItem key={item.key}>
                  <img
                    src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box> */}

          <CustomFormLabel>{t('common.gallery')}</CustomFormLabel>

          <FileUpload setImageFile={setImageFile} imageFile={imageFile} />
          <Typography sx={{ marginTop: 2 }} variant="h6">
            {t('common.currentVideos')}
          </Typography>
          <VideoView item={news?.news?.video_array} contentId={newsId} name="news" />
          <CustomFormLabel>{t('common.video')}</CustomFormLabel>
          <VideoUpload setVideoFile={setVideoFile} videoFile={videoFile} />
          <CustomAutoComplete
            control={control}
            defaultValue={defValues}
            errors={errors}
            id="categories"
            label={t('common.categories')}
            options={categories}
            multiple
          />
          <CustomSelect
            id="sectors"
            label={t('common.sectors')}
            errors={errors}
            control={control}
            defaultValue={news?.news?.field[0]?.id}
          >
            {sectors?.map((item) => (
              <MenuItem key={item?.value} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </CustomSelect>
          <CustomSelect
            id="status"
            label={t('common.status')}
            control={control}
            defaultValue={news?.news?.status}
          >
            {Object.keys(SuccessStory).map((key) => (
              <MenuItem key={key} value={SuccessStory[key]}>
                {key}
              </MenuItem>
            ))}
          </CustomSelect>
          <Box sx={{ mt: 3 }}>
            <Controller
              control={control}
              name="publishingDate"
              render={({ field: { _onChange, _value } }) => (
                <LocalizationProvider dateAdapter={DateFnsAdapter}>
                  <DatePicker
                    minDate={new Date()}
                    label={t('common.publishingDate')}
                    fullWidth
                    onChange={(_date) => {
                      setDate(_date);
                    }}
                    value={date || news?.news?.publish_date}
                    renderInput={(params) => (
                      <TextField {...params} error={!isUndefined(errors.publishingDate)} />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Box>
          <ErrorMessage error={errors.publishingDate} msg={errors.publishingDate?.message} />
          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default EditNews;
