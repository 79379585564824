import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export default function useEditCausesSchema() {
  const { t } = useTranslation();

  return yup.object({
    isActive: yup.bool().default(false).required(t('errors.required')),

    HeadlineAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    HeadlineEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    DescriptionAR: yup.string().required(t('errors.required')),
    DescriptionEN: yup.string().required(t('errors.required')),
    amount: yup
      .number()
      .integer()
      .transform((value) => (Number.isNaN(value) ? -1 : value))
      .min(0, t('errors.minValue', { minValue: 0 }))
      .required(t('errors.required')),
    categories: yup
      .array(
        yup.object({
          label: yup.string(),
          value: yup.number(),
        }),
      )
      .default([]),
    image: yup.mixed().transform((files) => (files.length > 0 ? files[0] : null)),
  });
}
