import { Box, Card, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm, Controller } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import { useAuthentication, useCategories } from '../../hooks';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useEditResearchSchema from './_hooks/useEditResearchSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import { Sources } from '../../constants';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import ErrorMessage from '../../components/ErrorMessage';
import CustomSwitch from '../../components/forms/custom-elements/CustomSwitch';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import useResearch from './_hooks/useResearch';
import { Navigate, useNavigate, useParams } from 'react-router';
import Spinner from '../spinner/Spinner';
import i18next from 'i18next';
import ReportUpload from '../../components/ReportUpload';
import VideoView from '../../components/VideoView';

const EditResearch = () => {
  const { researchId } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const pageTitle = t('research.editResearch');
  const schema = useEditResearchSchema();
  const { tokenApi } = useAuthentication();

  const [report, setReport] = useState([]);
  const { research, isLoading: isresearchLoading } = useResearch(researchId);
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(false);
  const defaultValues = { eventDate: research?.date };
  const alert = useAlert();
  const defValues = [];
  let { categories } = useCategories();
  categories = categories?.data?.map((cat) =>
    i18next.language === 'en'
      ? {
          label: cat.name.en,
          value: cat.id,
        }
      : {
          label: cat.name.ar,
          value: cat.id,
        },
  );

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/research',
      title: t('research.name'),
    },
    {
      title: pageTitle,
    },
  ];

  console.log(watch('eventDate'), 'watch');
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();
      console.log(form, 'jk');
      if (report.length >= 1) {
        report.forEach((img, index) => {
          formData.append(`attachments[${index}]`, img);
        });
      }

      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });
      formData.append('title', headline);
      formData.append('description', description);
      formData.append('status', form.isActive === true ? 'active' : 'inactive');
      formData.append('source', form.sources);
      formData.append(
        'date',
        (form.eventDate?.length === 4 ? form.eventDate : new Date(form.eventDate).getFullYear()) ||
          research?.date,
      );

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/researches/${researchId}?_method=put `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/research');
    } catch (err) {
      console.log(err);
      // Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (isresearchLoading || !research) {
    return <Spinner />;
  }
  if (defValues.length === 0) {
    research?.research?.categories.map((cat) => {
      return i18next.language === 'en'
        ? defValues.push({ label: cat?.name.en, value: cat?.id })
        : defValues.push({ label: cat?.name.ar, value: cat?.id });
    });
  }
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
            defaultValue={research?.title?.ar}
          />
          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue={research?.description?.ar}
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            defaultValue={research?.title?.en}
            errors={errors}
          />
          <CustomTextEditor
            defaultValue={research?.description?.en}
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
          />
          {/* General Translation */}

          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>

          <Typography sx={{ marginTop: 2 }} variant="h6">
            {t('common.currentReports')}
          </Typography>
          <VideoView item={research?.attachments} contentId={researchId} name="researches" />
          <CustomFormLabel>{t('common.reports')}</CustomFormLabel>

          <ReportUpload setReport={setReport} report={report} />

          <CustomSelect
            defaultValue={research?.source}
            id="sources"
            label={t('common.sources')}
            control={control}
          >
            {Object.keys(Sources).map((key) => (
              <MenuItem key={key} value={Sources[key]}>
                {key}
              </MenuItem>
            ))}
          </CustomSelect>

          <Box sx={{ mt: 3 }}>
            <Controller
              control={control}
              name="eventDate"
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={DateFnsAdapter}>
                  <DatePicker
                    label={t('common.eventDate')}
                    views={['year']}
                    fullWidth
                    onChange={(date, ...rest) => {
                      onChange(date);
                    }}
                    value={value || research?.date}
                    renderInput={(params) => (
                      <TextField {...params} error={!isUndefined(errors.eventDate)} />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Box>
          <ErrorMessage error={errors.eventDate} msg={errors.eventDate?.message} />
          <CustomSwitch
            defaultValue={research?.status === 'active' ? 1 : 0}
            id="isActive"
            label={t('common.active')}
            control={control}
          />
          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default EditResearch;
