import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { Box, Card, CircularProgress, Typography, MenuItem } from '@mui/material';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import Map from '../../components/map/map';
import { useForm } from 'react-hook-form';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import { yupResolver } from '@hookform/resolvers/yup';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import useAddBranchSchema from './_hooks/useAddBranchSchema';
import PageContainer from '../../components/container/PageContainer';
import axios from 'axios';
import useCountries from './_hooks/useCountries';

import ApiConfig from '../../config/api.config';
import { Navigate, useNavigate } from 'react-router';
import { useAuthentication } from '../../hooks';

const AddBranch = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const { tokenApi } = useAuthentication();

  const { countries, isLoading: isCountriesLoading } = useCountries();
  const [selectedGeo, setSelectedGeo] = useState({});
  const [location, setLocation] = useState(null);
  const addBranchSchema = useAddBranchSchema();

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    register,
    getValues,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(addBranchSchema),
  });

  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = {};

      const name = {
        en: form.nameEN,
        ar: form.nameAR,
      };

      formData.name = name;
      formData.branch_name = name;
      formData.phone = `+20${form.phone}`;
      formData.country = form.country;
      formData.latitude = selectedGeo.lat;
      formData.longitude = selectedGeo.lng;
      formData.branch_location = location;
      if (!formData.branch_location || !formData.country) {
        alert.error(t('errors.required'));
      } else {
        await axios({
          method: 'POST',
          url: `${ApiConfig.baseURL}/api/cities`,
          headers: { Authorization: 'Bearer ' + tokenApi },

          data: formData,
        });
        alert.success(t('common.addedSuccessfully'));
      }
      setLoading(false);
      navigate('/branches');
    } catch (err) {
      console.log(err, 'error');
      setLoading(false);
    }
  };

  const BCrumb = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      title: t('branches.addBranch'),
    },
  ];

  if (isCountriesLoading) {
    return <CircularProgress size={40} />;
  }
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <Box>
      <PageContainer title={t('branches.addBranch')} description={t('branches.addBranchDesc')}>
        <Breadcrumb title={t('branches.addBranch')} items={BCrumb} />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.arabicSection')}
            </Typography>
            <CustomInput
              id="nameAR"
              label={t('common.name')}
              errors={errors}
              {...register('nameAR')}
            />

            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.englishSection')}
            </Typography>
            <CustomInput
              id="nameEN"
              label={t('common.name')}
              errors={errors}
              {...register('nameEN')}
            />

            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.generalSection')}
            </Typography>

            <CustomSelect
              errors={!getValues('country')}
              id="country"
              label={t('common.city')}
              control={control}
              externalOnChange={(e) => setValue('country', e.target.value)}
            >
              {countries?.map((country, key) => (
                <MenuItem key={key} value={country.id}>
                  {country?.name[i18n.language]}
                </MenuItem>
              ))}
            </CustomSelect>

            <CustomInput
              id="phone"
              label={t('common.phoneNumber')}
              errors={errors}
              {...register('phone')}
            />

            <Map
              zoom={7}
              setSelected={setSelectedGeo}
              selected={selectedGeo}
              setLocation={setLocation}
              location={location}
            />
            <SubmitButton isLoading={loading} />
          </form>
        </Card>
      </PageContainer>
    </Box>
  );
};

export default AddBranch;
