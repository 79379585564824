import { useSelector } from 'react-redux';
import { getInitials } from '../utils/utils';

const useUser = () => {
  const user = useSelector((state) => state.UserReducer)?.user;
  const userRole = user?.role;
  const initials = user?.name ? getInitials(user.name) : undefined;

  return {
    user,
    role: userRole,
    initials,
  };
};

export default useUser;
