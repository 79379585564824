import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { Box, Card, MenuItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import useAddTrusteesSchema from './_hooks/useAddTrusteesSchema';
import PageContainer from '../../components/container/PageContainer';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import { Navigate, useNavigate } from 'react-router';
import { useAuthentication } from '../../hooks';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import { Commite } from '../../constants';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';

const defaultValues = {
  type: Commite.Trustee,
};

// TODO: check why is exception thrown in console after submitting category with languages
const AddTrustees = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const alert = useAlert();
  const { tokenApi } = useAuthentication();

  const [loading, setLoading] = useState(false);

  const AddTrusteesSchema = useAddTrusteesSchema();

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(AddTrusteesSchema),
    defaultValues,
  });
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      const name = JSON.stringify({
        en: form.nameEN,
        ar: form.nameAR,
      });
      const title = JSON.stringify({
        en: form.titleEN,
        ar: form.titleAR,
      });
      const description = JSON.stringify({
        en: form.descriptionEN,
        ar: form.descriptionAR,
      });
      formData.append('name', name);
      formData.append('description', description);
      formData.append('title', title);
      formData.append('type', form.type);

      if (form.image) {
        formData.append('image', form.image);
      }
      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/trustees `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate("/trustees");
    } catch (err) {
      console.log(err, 'error');
      setLoading(false);
    }
  };

  const BCrumb = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      title: t('trustees.trustees'),
    },
  ];
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <Box>
      <PageContainer title={t('trustees.trustees')} description={t('trustees.addTrusteesDesc')}>
        <Breadcrumb title={t('trustees.trustees')} items={BCrumb} />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.arabicSection')}
            </Typography>
            <CustomInput
              id="nameAR"
              label={t('common.name')}
              errors={errors}
              {...register('nameAR')}
            />
            <CustomInput
              id="titleAR"
              label={t('common.title')}
              errors={errors}
              {...register('titleAR')}
            />
            <CustomTextEditor
              id="descriptionAR"
              label={t('successStory.description')}
              control={control}
              errors={errors}
              defaultValue=""
            />
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.englishSection')}
            </Typography>
            <CustomInput
              id="nameEN"
              label={t('common.name')}
              errors={errors}
              {...register('nameEN')}
            />
            <CustomInput
              id="titleEN"
              label={t('common.title')}
              errors={errors}
              {...register('titleEN')}
            />
            <CustomTextEditor
              id="descriptionEN"
              label={t('successStory.description')}
              control={control}
              errors={errors}
              defaultValue=""
            />
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.generalSection')}
            </Typography>
            <CustomInput
              id="image"
              errors={errors}
              {...register('image')}
              label={t('common.mainImage')}
              type="file"
              accept="image/png,image/jpeg,image/jpg"
            />
            <CustomSelect id="type" label={t('common.type')} control={control} defaultValue={[]}>
              {Object.keys(Commite).map((key) => (
                <MenuItem key={key} value={Commite[key]}>
                  {key}
                </MenuItem>
              ))}
            </CustomSelect>
            <Box sx={{ mt: 2 }}>
              <LoadingButton type="submit" variant="contained" loading={loading}>
                {t('common.submit')}
              </LoadingButton>
            </Box>
          </form>
        </Card>
      </PageContainer>
    </Box>
  );
};

export default AddTrustees;
