import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomPaginatedTable from '../../components/tables/CustomPaginatedTable';
import RowEditButton from '../../components/tables/RowEditButton';
import i18next from 'i18next';
import { Link, Navigate } from 'react-router-dom';
import { useAuthentication } from '../../hooks';

const Training = () => {
  const [training, setTraining] = useState([]);
  const { tokenApi } = useAuthentication();

  const { t } = useTranslation();

  const BCrumb = [
    {
      to: '/',
      title: t('home.homepage'),
    },

    {
      title: t('training.trainingListing'),
    },
  ];
  const headCells = [
    {
      id: 'trainingId',
      label: t('common.id'),
    },
    {
      id: 'trainingHeadline',
      label: t('common.headline'),
    },
    {
      id: 'trainingstatus',
      label: t('common.status'),
    },
    {
      id: 'NewsLastUpdateTimestamp',
      label: t('common.lastUpdateTime'),
    },
  ];
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <Box>
      <PageContainer
        title={t('training.trainingListing')}
        description={t('training.bundlesListDesc')}
      >
        <Breadcrumb title={t('training.trainingListing')} items={BCrumb} />
        <CustomPaginatedTable
          headCells={headCells}
          includeActions
          name="Training"
          responseKey="Training"
          setEntities={setTraining}
          url="api/trainings"
        >
          {training?.map(({ id, title, updated_at, status }) => {
            return (
              <TableRow key={id}>
                <TableCell align="center" component="th" id={id} scope="row" padding="none">
                  {id}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" fontWeight="600">
                    {i18next.language === 'en' ? title?.en : title?.ar}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Box
                      sx={{
                        backgroundColor:
                          status === true
                            ? (theme) => theme.palette.success.main
                            : (theme) => theme.palette.error.main,
                        borderRadius: '100%',
                        height: '10px',
                        width: '10px',
                      }}
                    />
                    <TableCell align="center">
                      <Typography variant="h6" fontWeight="600">
                        {status === true ? 'Active' : 'InActive'}
                      </Typography>
                    </TableCell>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color="textSecondary"
                    sx={{
                      fontSize: 'h6.fontSize',
                    }}
                  >
                    {updated_at}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <RowEditButton url={`/training/edit/${id}`} />
                  <Tooltip title={t('common.trainee')}>
                    <IconButton component={Link} to={`/training/trainee/${id}`}>
                      <FeatherIcon icon="plus" width="18" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
        </CustomPaginatedTable>
      </PageContainer>
    </Box>
  );
};

export default Training;
