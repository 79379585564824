import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, CircularProgress, MenuItem, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router';
import PageContainer from '../../components/container/PageContainer';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import ApiConfig from '../../config/api.config';
import { Payment } from '../../constants';
import { useAuthentication } from '../../hooks';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import usePayment from './_hooks/usePayment';
import usePaymentSchema from './_hooks/usePaymentSchema';

const EditPyment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();

  const { methodId } = useParams();
  const { tokenApi, isAuthorized } = useAuthentication();
  const [loading, setLoading] = React.useState(false);
  const [imageFile, setImageFile] = React.useState([]);
  const { paymentMethod } = usePayment(methodId);
  const schema = usePaymentSchema(true);
  console.log('payr=ment', paymentMethod?.name?.ar);
  const bCrumb = [{ to: '/', title: t('home.homepage') }, { title: t('payment.edit') }];

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      HeadlineAR: paymentMethod?.name?.ar,
      HeadlineEN: paymentMethod?.name?.en,
      DescriptionAR: paymentMethod?.description?.ar,
      DescriptionEN: paymentMethod?.description?.en,
      type: paymentMethod?.type === `${Payment.Ewallets}` ? Payment.Ewallets : Payment.Banks,
    },
  });
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      if (imageFile.length >= 1) {
        imageFile.forEach((img, index) => {
          formData.append(`image_array[${index}]`, img);
        });
      }

      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });
      formData.append('name', headline);
      formData.append('description', description);
      if (form.image) {
        formData.append('main_image', form.image);
      }

      if(form.type) {
        formData.append('type', form.type);
      }
      
      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/payments/${methodId}?_method=put `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      }).then(() => {
        alert.success(t('common.addedSuccessfully'));
        setLoading(false);
        navigate('/payment/paymentlist');
      });
    } catch (err) {
      console.log(err.data);
      // alert?.error(err?.response?.data?.data?.message);
      // Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  } else if (!paymentMethod) {
    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <div>
      <PageContainer title={t('payment.edit')}>
        <Breadcrumb title={t('payment.edit')} items={bCrumb} />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Arabic Translation */}
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.arabicSection')}
            </Typography>
            <CustomInput
              id="HeadlineAR"
              label={t('successStory.headline')}
              {...register('HeadlineAR')}
              errors={errors}
              defaultValue={paymentMethod?.name?.ar}
            />
            <CustomTextEditor
              id="DescriptionAR"
              label={t('successStory.description')}
              control={control}
              errors={errors}
              defaultValue={paymentMethod?.description?.ar}
            />
            {/* English Translation */}
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.englishSection')}
            </Typography>
            <CustomInput
              id="HeadlineEN"
              label={t('successStory.headline')}
              {...register('HeadlineEN')}
              errors={errors}
              defaultValue={paymentMethod?.name?.en}
            />
            <CustomTextEditor
              id="DescriptionEN"
              label={t('successStory.description')}
              control={control}
              errors={errors}
              defaultValue={paymentMethod?.description?.en}
            />
            {/* General Translation */}

            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.generalSection')}
            </Typography>
            <CustomInput
              id="currentImage"
              label={t('common.currentImage')}
              errors={errors}
              {...register('currentImage')}
              defaultValue={paymentMethod?.main_image?.url}
              disabled
            />
            <CustomInput
              id="image"
              label={t('common.mainImage')}
              type="file"
              errors={errors}
              accept="image/png,image/jpeg,image/jpg"
              {...register('image')}
            />

            <CustomSelect id="type" label={t('common.type')} control={control} defaultValue={[]}>
              {Object.keys(Payment).map((key) => (
                <MenuItem key={key} value={Payment[key]}>
                  {key}
                </MenuItem>
              ))}
            </CustomSelect>
            <Box sx={{ mt: 2 }}>
              <SubmitButton isLoading={loading} />
            </Box>
          </form>
        </Card>
      </PageContainer>
    </div>
  );
};

export default EditPyment;
