import { areArraysEqual } from '@mui/base';
import { useEffect, useState } from 'react';
import { useHttpHook } from '../../../hooks';

export default function useTrustees(trusteeId) {
  const { sendRequest, isLoading } = useHttpHook();
  const [trustees, setTrustees] = useState();

  useEffect(() => {
    const fetchCategory = () =>
      sendRequest({
        url: `api/trustees/${trusteeId}`,
      });

    (async () => {
      const response = await fetchCategory();
      if (response.tr) {
        const { id, ...rest } = response.tr;
        Object.assign(response, rest);
      }
      setTrustees(response);
    })();
  }, []);
  return {
    isLoading,
    trustees,
  };
}
