import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function RowEditButton({ url }) {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('common.edit')}>
      <IconButton component={Link} to={url}>
        <FeatherIcon icon="edit-3" width="18" />
      </IconButton>
    </Tooltip>
  );
}

RowEditButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export default RowEditButton;
