import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';

/* ***News**** */
import AddNews from '../views/news/AddNews';
import News from '../views/news/News';
import EditNews from '../views/news/EditNews';

/* ***Events**** */
import AddEvent from '../views/events/AddEvent';
import Events from '../views/events/Events';
import EditEvent from '../views/events/EditEvent';

/* ***story**** */
import SuccessStory from '../views/successStory/SuccessStory';
import EditSuccessStory from '../views/successStory/EditSuccessStory';
import AddSuccessStory from '../views/successStory/AddSuccessStory';

/* ***causes**** */
import Causes from '../views/causes/Causes';
import AddCauses from '../views/causes/AddCauses';
import EditCauses from '../views/causes/EditCauses';

/* ***products**** */
import Products from '../views/products/Products';
import EditProducts from '../views/products/EditProducts';
import AddProducts from '../views/products/AddProducts';

/* ***project**** */
import Project from '../views/_project/Project';
import EditProject from '../views/_project/EditProject';
import AddProject from '../views/_project/AddProject';

/* ***research**** */
import Research from '../views/research/Research';
import EditResearch from '../views/research/EditResearch';
import AddResearch from '../views/research/AddResearch';

/* ***career**** */
import Career from '../views/careers/Career';
import CareerApplicants from '../views/careers/CareerApplicants';
import EditCareer from '../views/careers/EditCareer';
import EditCareerApplicants from '../views/careers/EditCareerApplicants';
import AddCareer from '../views/careers/AddCareer';

/* ***training**** */
import Training from '../views/training/Training';
import EditTraining from '../views/training/EditTraining';
import AddTraining from '../views/training/AddTraining';
import Trainees from '../views/training/Trainees';
import EditTrainees from '../views/training/EditTrainees';

/* ***News**** */
import AddTrustees from '../views/trustees/AddTrustees';
// import News from '../views/news/News';
// import EditNews from '../views/news/EditNews';

/* ***delegations**** */
import DelegationListing from '../views/delegations/DelegationListing';
import EditDelegations from '../views/delegations/EditDelegations';

/* ***inkind**** */
import InKind from '../views/inKind/InKind';
import EditInKind from '../views/inKind/EditInKind';

/* ***volunteer**** */
import EditVolunteer from '../views/volunteer/EditVolunteer';
import Volunteer from '../views/volunteer/Volunteer';

/* ***supplier**** */
import EditSupplier from '../views/supplier/EditSupplier';
import Supplier from '../views/supplier/Supplier';

/* ***sectors**** */
import Sectors from '../views/sectors/Sectors';
import EditSectors from '../views/sectors/EditSectors';
import AddSectors from '../views/sectors/AddSectors';
/* ***partners**** */
import PartnersList from '../views/partners/PartnerList';
import EditPartners from '../views/partners/EditPartner';
import AddPartner from '../views/partners/AddPartner';

/* ***Category**** */
import AddCategory from '../views/categories/AddCategory';
import Categories from '../views/categories/Categories';
import EditCategory from '../views/categories/EditCategory';

/* ***Branches**** */
import AddBranch from '../views/branches/addBranch';
import Branches from '../views/branches/branches';
import EditBranches from '../views/branches/editBranches';
import Home from '../views/home/Home';
import Trustees from '../views/trustees/Trustees';
import EditTrustees from '../views/trustees/EditTrustees';
/* **Static Content** */
import AddContentType from '../views/content/AddContentType';
import ContentList from '../views/content/ContentList';
import ContentTypeList from '../views/content/ContentTypeList';
import AddContent from '../views/content/AddContent';
import PaymentList from '../views/payment/PaymentList';
import AddPayment from '../views/payment/AddPayment';
import EditContentTypes from '../views/content/EditContentTypes';
import EditContnetItem from '../views/content/EditContnetItem';
import EditPyment from '../views/payment/EditPyment';
import BanksList from '../views/banks/BanksList';
import AddBanks from '../views/banks/AddBanks';
import PortalUsers from '../views/users/PortalUsers';
import BackOfficeUsers from '../views/users/BackOfficeUsers';
import AddBackOfficeUser from '../views/users/AddBackOfficeUser';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));

/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [{ path: '/', element: <Home /> }],
  },
  {
    path: '/successStory',
    element: <FullLayout />,
    children: [
      { path: 'add', element: <AddSuccessStory /> },
      { path: '/successStory', element: <SuccessStory /> },
      { path: 'edit/:storyId', element: <EditSuccessStory /> },
    ],
  },
  {
    path: '/news',
    element: <FullLayout />,
    children: [
      { path: '/news', element: <News /> },
      { path: 'add', element: <AddNews /> },
      { path: 'edit/:newsId', element: <EditNews /> },
    ],
  },
  {
    path: '/events',
    element: <FullLayout />,
    children: [
      { path: '/events', element: <Events /> },
      { path: 'add', element: <AddEvent /> },
      { path: 'edit/:eventId', element: <EditEvent /> },
    ],
  },
  {
    path: '/causes',
    element: <FullLayout />,
    children: [
      { path: '/causes', element: <Causes /> },
      { path: 'add', element: <AddCauses /> },
      { path: 'edit/:causeId', element: <EditCauses /> },
    ],
  },
  {
    path: '/products',
    element: <FullLayout />,
    children: [
      { path: '/products', element: <Products /> },
      { path: 'add', element: <AddProducts /> },
      { path: 'edit/:productId', element: <EditProducts /> },
    ],
  },
  {
    path: '/trustees',
    element: <FullLayout />,
    children: [
      { path: '/trustees', element: <Trustees /> },
      { path: 'add', element: <AddTrustees /> },
      { path: 'edit/:trusteeId', element: <EditTrustees /> },
    ],
  },
  {
    path: '/project',
    element: <FullLayout />,
    children: [
      { path: '/project', element: <Project /> },
      { path: 'add', element: <AddProject /> },
      { path: 'edit/:projectId', element: <EditProject /> },
    ],
  },
  {
    path: '/research',
    element: <FullLayout />,
    children: [
      { path: '/research', element: <Research /> },
      { path: 'add', element: <AddResearch /> },
      { path: 'edit/:researchId', element: <EditResearch /> },
    ],
  },
  {
    path: '/career',
    element: <FullLayout />,
    children: [
      { path: '/career', element: <Career /> },
      { path: 'add', element: <AddCareer /> },
      { path: 'edit/:careerId', element: <EditCareer /> },
      { path: 'applicants/:jobId', element: <CareerApplicants /> },
      { path: 'applicants/edit/:jobId', element: <EditCareerApplicants /> },
    ],
  },
  {
    path: '/training',
    element: <FullLayout />,
    children: [
      { path: '/training', element: <Training /> },
      { path: 'add', element: <AddTraining /> },
      { path: 'edit/:trainingId', element: <EditTraining /> },
      { path: 'trainee/:traineeId', element: <Trainees /> },
      { path: 'trainee/edit/:traineeId', element: <EditTrainees /> },
    ],
  },
  {
    path: '/delegation',
    element: <FullLayout />,
    children: [
      { path: '/delegation', element: <DelegationListing /> },
      { path: 'edit/:delegationId', element: <EditDelegations /> },
    ],
  },
  {
    path: '/InKind',
    element: <FullLayout />,
    children: [
      { path: '/InKind', element: <InKind /> },
      { path: 'edit/:inkindId', element: <EditInKind /> },
    ],
  },
  {
    path: '/volunteer',
    element: <FullLayout />,
    children: [
      { path: '/volunteer', element: <Volunteer /> },
      { path: 'edit/:volunteerId', element: <EditVolunteer /> },
    ],
  },
  {
    path: '/supplier',
    element: <FullLayout />,
    children: [
      { path: '/supplier', element: <Supplier /> },
      { path: 'edit/:supplierId', element: <EditSupplier /> },
    ],
  },
  {
    path: '/sectors',
    element: <FullLayout />,
    children: [
      { path: '/sectors', element: <Sectors /> },
      { path: 'add', element: <AddSectors /> },
      { path: 'edit/:sectorId', element: <EditSectors /> },
    ],
  },
  {
    path: '/categories',
    element: <FullLayout />,
    children: [
      { path: '/categories', element: <Categories /> },
      { path: 'add', element: <AddCategory /> },
      { path: 'edit/:categoryId', element: <EditCategory /> },
    ],
  },
  {
    path: '/branches',
    element: <FullLayout />,
    children: [
      { path: '/branches', element: <Branches /> },
      { path: 'add', element: <AddBranch /> },
      { path: 'edit/:branchId', element: <EditBranches /> },
    ],
  },
  {
    path: '/partners',
    element: <FullLayout />,
    children: [
      { path: '/partners', element: <PartnersList /> },
      { path: 'add', element: <AddPartner /> },
      { path: 'edit/:partnerId', element: <EditPartners /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/content',
    element: <FullLayout />,
    children: [
      { path: 'contentlist', element: <ContentList /> },
      { path: 'addcontent', element: <AddContent /> },
      { path: 'addtype', element: <AddContentType /> },
      { path: 'contenytypelist', element: <ContentTypeList /> },
      { path: 'edit/:typeId', element: <EditContentTypes /> },
      { path: 'contnetitem/edit/:contentId', element: <EditContnetItem /> },
    ],
  },
  {
    path: '/payment',
    element: <FullLayout />,
    children: [
      { path: 'paymentlist', element: <PaymentList /> },
      { path: 'addpayment', element: <AddPayment /> },
      { path: 'edit/:methodId', element: <EditPyment /> },
    ],
  },
  {
    path: '/banks',
    element: <FullLayout />,
    children: [
      { path: 'banksList', element: <BanksList /> },
      { path: 'addBank', element: <AddBanks /> },
    ],
  },
  {
    path: '/users',
    element: <FullLayout />,
    children: [
      { path: 'usersList', element: <PortalUsers /> },
      { path: 'backoffice', element: <BackOfficeUsers /> },
      { path: 'addbackoffice', element: <AddBackOfficeUser /> },
    ],
  },
];

export default Router;
