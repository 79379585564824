import { Box, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHttpHook } from '../hooks';
import { useAlert } from 'react-alert';
import './image.css';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ApiMethods } from '../services/api.service';
const ImageView = ({ item, contentId, name }) => {
  const [display, setDisplay] = useState(null);
  const { t } = useTranslation();
  const alert = useAlert();
  const { sendRequest } = useHttpHook();
  const [itemPreview, setItemPreview] = useState((item?.length > 0 && item) || []);

  const handleDelete = async (id) => {
    try {
      await sendRequest({
        url: `api/${name}/files/${contentId}/${id}`,
        method: ApiMethods.DELETE,
      });
      await alert.success(t('common.deletedSuccessfully'));
      setItemPreview((prevState) => prevState?.filter(({ id: pid }) => id !== pid));
    } catch {
      //
    }
  };
  return (
    <>
      <Box
        style={{
          border: '1px solid lightgrey',
          borderRadius: '5px',
          padding: '30px',
          marginTop: '30px',
        }}
        sx={{ flexGrow: 1 }}
      >
        <Grid container spacing={3}>
          {itemPreview?.map((ite) => (
            <Grid
              item
              key={ite.id}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              sx={{
                position: 'relative',
              }}
            >
              <div
                className="image"
                onMouseEnter={() => setDisplay(ite.id)}
                onMouseLeave={() => setDisplay(null)}
              >
                <img
                  style={{
                    width: '100%',
                    height: '220px',
                    boxShadow: '5px 5px 10px #b2bec3',
                    borderRadius: '12px',
                  }}
                  src={ite.url}
                />
                <IconButton
                  onClick={() => handleDelete(ite.id)}
                  sx={{
                    position: 'absolute',
                    top: '45%',
                    right: '45%',
                    transition: 'ease-in-out 0.2s',
                    opacity: display === ite.id ? 1 : 0,
                    zIndex: 999,
                    color: 'rgba(183, 21, 64,1.0)',
                    transform: 'scale(3,3)',
                    ':hover': { transform: 'scale(3.5,3.5)', transition: 'ease-in-out 0.2s' },
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ImageView;
