import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router';
import PageContainer from '../../components/container/PageContainer';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import { useAuthentication } from '../../hooks';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import useBanksSchema from './hooks/useBanksSchema';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import useSingleSector from '../../hooks/useSingleSector';
import i18next from 'i18next';

const AddBanks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { tokenApi, isAuthorized } = useAuthentication();
  const [loading, setLoading] = React.useState(false);
  const schema = useBanksSchema();
  const alert = useAlert();
  let { sectors } = useSingleSector();
  //   let { currencies } = useSinglecurrencies();
  const bCrumb = [{ to: '/', title: t('home.homepage') }, { title: t('banks.add') }];
  const defaultValues = {};
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });
  sectors = sectors?.data?.map((cat) =>
    i18next.language === 'en'
      ? {
          label: cat?.title?.en,
          value: cat?.id,
        }
      : {
          label: cat?.title?.ar,
          value: cat?.id,
        },
  );
  //   currencies = currencies?.data?.map((cat) => {
  //     return {
  //       label: cat?.name,
  //       value: cat?.id,
  //     };
  //   });
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = {};
      const currencies = [{ swift_code: form.swift_code, IBAN: form.IBAN }];
      const sectors = [form.sectors];

      const name = {
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      };
      formData.name = name;
      formData.currencies = currencies;
      if (sectors.length > 0) {
        formData.sectors = sectors;
      }
      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/banks `,
        data: formData,
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + tokenApi },
      }).then(() => {
        navigate('/banks/banksList');
        alert.success(t('common.addedSuccessfully'));
        setLoading(false);
      });
    } catch (err) {
      console.log(err.data);
      // alert?.error(err?.response?.data?.data?.message);
      // Object.values(err?.response?.data?.data)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  //   console.log(currencies);
  return (
    <div>
      <PageContainer title={t('banks.add')}>
        <Breadcrumb title={t('banks.add')} items={bCrumb} />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Arabic Translation */}
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.arabicSection')}
            </Typography>
            <CustomInput
              id="HeadlineAR"
              label={t('banks.name')}
              {...register('HeadlineAR')}
              errors={errors}
            />

            {/* English Translation */}
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.englishSection')}
            </Typography>
            <CustomInput
              id="HeadlineEN"
              label={t('banks.name')}
              {...register('HeadlineEN')}
              errors={errors}
            />

            {/* General Translation */}

            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('banks.currency')}
            </Typography>
            <CustomInput
              id="HeadlineEN"
              label={t('banks.iban')}
              {...register('IBAN')}
              errors={errors}
            />
            <CustomInput
              id="HeadlineEN"
              label={t('banks.swift')}
              {...register('swift_code')}
              errors={errors}
            />

            <CustomSelect
              id="sectors"
              label={t('common.sectors')}
              control={control}
              errors={errors}
            >
              {sectors?.map((item) => (
                <MenuItem key={item?.value} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </CustomSelect>

            <Box sx={{ mt: 2 }}>
              <SubmitButton isLoading={loading} />
            </Box>
          </form>
        </Card>
      </PageContainer>
    </div>
  );
};

export default AddBanks;
