import { Box, Card, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useEditCareerSchema from './_hooks/useEditCareerSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import axios from 'axios';
import useCareer from './_hooks/useCareer';
import ApiConfig from '../../config/api.config';
import CustomSwitch from '../../components/forms/custom-elements/CustomSwitch';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import { Navigate, useNavigate, useParams } from 'react-router';
import Spinner from '../spinner/Spinner';
import { useAuthentication } from '../../hooks';

const EditCareer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { careerId } = useParams();
  const { tokenApi } = useAuthentication();
  const { career, isLoading: isCareerLoading } = useCareer(careerId);
  const pageTitle = t('career.career');
  const schema = useEditCareerSchema();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const {
    formState: { errors },
    register,
    handleSubmit,

    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/career',
      title: t('career.name'),
    },
    {
      title: pageTitle,
    },
  ];
  console.log(errors);
  const onSubmit = async (form) => {
    try {
      setLoading(true);

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/careers/${careerId}?_method=put`,
        headers: { Authorization: 'Bearer ' + tokenApi },

        data: {
          description: {
            en: form.DescriptionEN,
            ar: form.DescriptionAR,
          },
          title: {
            en: form.HeadlineEN,
            ar: form.HeadlineAR,
          },
          status: form.isActive === true ? 1 : 0,
        },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/career');
    } catch (err) {
      console.log(err, 'error');
      setLoading(false);
    }
  };
  if (isCareerLoading || !career) {
    return <Spinner />;
  }
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
            defaultValue={career?.title?.ar}
          />

          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue={career?.description?.ar}
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            errors={errors}
            defaultValue={career?.title?.en}
          />

          <CustomTextEditor
            defaultValue={career?.description?.en}
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
          />
          {/* General Translation */}

          <Typography sx={{ marginTop: 2, paddingBottom: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>

          <Box>
            <CustomSwitch
              sx={{ mt: 61 }}
              id="isActive"
              label={t('common.active')}
              control={control}
              defaultValue={career?.status}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default EditCareer;
