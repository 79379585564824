import React from 'react';
import {
  TableCell,
  TableRow,
  Typography,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/container/PageContainer';
import CustomPaginatedTable from '../../components/tables/CustomPaginatedTable';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import { useHttpHook } from '../../hooks';
import useConfirmationDialoge from '../../hooks/useConfirmationDialoge';
import { ApiMethods } from '../../services/api.service';
import FeatherIcon from 'feather-icons-react';

const BackOfficeUsers = () => {
  const { t, i18n } = useTranslation();
  const bCrumb = [{ to: '/', title: t('home.homepage') }, { title: t('userslist.list') }];
  const [types, setTypes] = React.useState([]);
  const [categoryId, setCategoryId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const { sendRequest } = useHttpHook();

  const { BootstrapDialog, BootstrapDialogTitle } = useConfirmationDialoge();
  const handleClickOpen = (id) => {
    setCategoryId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const headCells = [
    {
      id: 'typeId',
      label: t('userslist.firstname'),
    },
    {
      id: 'paymentName',
      label: t('userslist.lastName'),
    },
    {
      id: 'typeLast',
      label: t('userslist.phoneNumber'),
    },
    {
      id: 'email',
      label: t('userslist.email'),
    },
  ];
  const handleDelete = async (id) => {
    try {
      await sendRequest({
        url: `api/users/${id}`,
        method: ApiMethods.DELETE,
      });
      await alert.success(t('common.deletedSuccessfully'));
      setTypes((prevState) => prevState?.filter(({ id: pid }) => id !== pid));

      setOpen(false);
    } catch {
      //
    }
  };
  return (
    <div>
      <div>
        <PageContainer title={t('userslist.list')}>
          <Breadcrumb title={t('userslist.list')} items={bCrumb} />
          <CustomPaginatedTable
            headCells={headCells}
            includeActions
            setEntities={setTypes}
            url="/api/admins"
          >
            {types?.map(({ id, first_name, last_name, email, phone_number }, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow key={id}>
                  <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                    {first_name}
                  </TableCell>
                  {/* content name label */}
                  <TableCell align="center">{last_name}</TableCell>
                  <TableCell align="center">
                    <Typography
                      color="textSecondary"
                      sx={{
                        fontSize: 'h6.fontSize',
                      }}
                    >
                      {phone_number}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      color="textSecondary"
                      sx={{
                        fontSize: 'h6.fontSize',
                      }}
                    >
                      {email}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={t('common.delete')}>
                      <IconButton onClick={() => handleClickOpen(id)}>
                        <FeatherIcon icon="trash" width="18" />
                      </IconButton>
                    </Tooltip>
                    <BootstrapDialog
                      onClose={handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={open}
                    >
                      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {t('branches.deleteBranch')}
                      </BootstrapDialogTitle>
                      <DialogContent dividers>
                        <Typography gutterBottom>{t('common.confirmation')}</Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button autoFocus onClick={() => handleDelete(categoryId)}>
                          {t('common.ok')}
                        </Button>
                        <Button onClick={handleClose}>{t('common.cancel')}</Button>
                      </DialogActions>
                    </BootstrapDialog>
                  </TableCell>
                </TableRow>
              );
            })}
          </CustomPaginatedTable>
        </PageContainer>
      </div>
    </div>
  );
};

export default BackOfficeUsers;
