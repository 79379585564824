import { transitions, positions } from 'react-alert';

const AlertConfig = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '10px',
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 999999,
  },
};

export default AlertConfig;
