import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const useAddContentSchema = () => {
  const { t } = useTranslation();
  return yup.object({
    HeadlineAR: yup.string(),
    HeadlineEN: yup.string(),
    DescriptionAR: yup.string().required(t('errors.required')),
    DescriptionEN: yup.string().required(t('errors.required')),
    types: yup.string().required(t('errors.required')),
    image: yup
      .mixed()
      .transform((files) => (files.length > 0 ? files[0] : null))
      .test('file-type', t('errors.fileFormat', { fileFormat: 'JPEG, JPG or PNG' }), (file) => {
        if (file == 'undefined' || file) {
          const fileExtension = file.name.split('.').at(-1);
          return ['png', 'jpg', 'jpeg'].includes(fileExtension.toLowerCase());
        } else {
          return true;
        }
      }),
  });
};

export default useAddContentSchema;
