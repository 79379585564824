import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomPaginatedTable from '../../components/tables/CustomPaginatedTable';
import RowEditButton from '../../components/tables/RowEditButton';
import { useParams } from 'react-router';

const Trainees = () => {
  const [trainee, setTrainees] = useState([]);
  const { traineeId } = useParams();
  const { t } = useTranslation();
  console.log(trainee, 'fghj');
  const BCrumb = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/training',
      title: t('trainee.name'),
    },
    {
      title: t('trainee.traineeListing'),
    },
  ];
  const headCells = [
    {
      id: 'jobId',
      label: t('common.id'),
    },
    {
      id: 'traineeHeadline',
      label: t('common.name'),
    },
    {
      id: 'traineeHeadline',
      label: t('common.phoneNumber'),
    },
    {
      id: 'traineestatus',
      label: t('common.status'),
    },
  ];

  return (
    <Box>
      <PageContainer title={t('trainee.traineeListing')} description={t('trainee.bundlesListDesc')}>
        <Breadcrumb title={t('trainee.traineeListing')} items={BCrumb} />
        <CustomPaginatedTable
          headCells={headCells}
          includeActions
          name="trainee"
          responseKey="trainee"
          setEntities={setTrainees}
          url={`api/trainees?filter[training]=${traineeId}`}
        >
          {trainee?.map(({ id, name, phone, status }) => {
            return (
              <TableRow key={id}>
                <TableCell align="center" component="th" id={id} scope="row" padding="none">
                  {id}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" fontWeight="600">
                    {name}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color="textSecondary"
                    sx={{
                      fontSize: 'h6.fontSize',
                    }}
                  >
                    {phone}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Box />
                    <TableCell align="center">
                      <Typography variant="h6" fontWeight="600">
                        {status}
                      </Typography>
                    </TableCell>
                  </Box>
                </TableCell>

                <TableCell align="center">
                  <RowEditButton url={`/training/trainee/edit/${id}`} />
                </TableCell>
              </TableRow>
            );
          })}
        </CustomPaginatedTable>
      </PageContainer>
    </Box>
  );
};

export default Trainees;
