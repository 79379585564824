import { useEffect, useState } from 'react';
import { useHttpHook } from '../../../hooks';

export default function useCountries() {
  const { sendRequest, isLoading } = useHttpHook();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = () =>
      sendRequest({
        url: 'api/countries',
      });

    (async () => {
      const response = await fetchCountries();
      if (response?.tr) {
        const { id, ...rest } = response?.tr;
        Object.assign(response, rest);
      }
      setCountries(response.data);
    })();
  }, []);

  return {
    isLoading,
    countries,
  };
}
