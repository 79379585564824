import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useAuthentication, useHttpHook } from '../../hooks';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomPaginatedTable from '../../components/tables/CustomPaginatedTable';
import RowEditButton from '../../components/tables/RowEditButton';
import { ApiMethods } from '../../services/api.service';
import useConfirmationDialoge from '../../hooks/useConfirmationDialoge';
import i18next from 'i18next';
import { Navigate } from 'react-router';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const { sendRequest } = useHttpHook();
  const { tokenApi } = useAuthentication();

  const { BootstrapDialog, BootstrapDialogTitle } = useConfirmationDialoge();
  const [open, setOpen] = React.useState(false);
  const [categoryId, setCategoryId] = useState();

  const { t } = useTranslation();
  const alert = useAlert();

  const BCrumb = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      title: t('categories.categoriesList'),
    },
  ];

  const headCells = [
    {
      id: 'categoryId',
      label: t('common.id'),
    },
    {
      id: 'categoryName',
      label: t('categories.categoryName'),
    },

    {
      id: 'categoryLastUpdateTimestamp',
      label: t('common.lastUpdateTime'),
    },
  ];

  const handleDelete = async (id) => {
    try {
      await sendRequest({
        url: `api/categories/${id}`,
        method: ApiMethods.DELETE,
      });
      await alert.success(t('common.deletedSuccessfully'));
      setCategories((prevState) => prevState?.filter(({ id: pid }) => id !== pid));

      setOpen(false);
    } catch {
      //
    }
  };

  const handleClickOpen = (id) => {
    setCategoryId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <Box>
      <PageContainer
        title={t('categories.categoriesList')}
        description={t('categories.categoriesListDesc')}
      >
        <Breadcrumb title={t('categories.categoriesList')} items={BCrumb} />
        <CustomPaginatedTable
          headCells={headCells}
          includeActions
          name="categories"
          responseKey="categories"
          setEntities={setCategories}
          url="api/categories"
        >
          {categories?.map(({ id, name, updated_at }, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow key={id}>
                <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                  {id}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" fontWeight="600">
                    {i18next.language === 'en' ? name.en : name.ar}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography
                    color="textSecondary"
                    sx={{
                      fontSize: 'h6.fontSize',
                    }}
                  >
                    {updated_at}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <RowEditButton url={`/categories/edit/${id}`} />

                  <Tooltip title={t('common.delete')}>
                    <IconButton onClick={() => handleClickOpen(id)}>
                      <FeatherIcon icon="trash" width="18" />
                    </IconButton>
                  </Tooltip>
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                      {t('categories.deleteCategory')}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                      <Typography gutterBottom>{t('common.confirmation')}</Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={() => handleDelete(categoryId)}>
                        {t('common.ok')}
                      </Button>
                      <Button onClick={handleClose}>{t('common.cancel')}</Button>
                    </DialogActions>
                  </BootstrapDialog>
                </TableCell>
              </TableRow>
            );
          })}
        </CustomPaginatedTable>
      </PageContainer>
    </Box>
  );
};

export default Categories;
