import React from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import AlertProvider from './components/providers/AlertProvider';
import 'react-perfect-scrollbar/dist/css/styles.css';

const queryClient = new QueryClient();

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <AlertProvider>
            <CssBaseline />
            {routing}
          </AlertProvider>
        </RTL>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="top-left" />
    </QueryClientProvider>
  );
};

export default App;
