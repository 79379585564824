import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export default function useAddProductsSchema() {
  const { t } = useTranslation();

  return yup.object({
    isActive: yup.bool().default(false).required(t('errors.required')),
    sectors: yup.string().required(t('errors.required')),

    HeadlineAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    HeadlineEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    DescriptionAR: yup.string().required(t('errors.required')),
    DescriptionEN: yup.string().required(t('errors.required')),
    units: yup
      .number()
      .integer()
      .transform((value) => (Number.isNaN(value) ? -1 : value))
      .min(0, t('errors.minValue', { minValue: 0 }))
      .required(t('errors.required')),
    value: yup
      .number()
      .integer()
      .transform((value) => (Number.isNaN(value) ? -1 : value))
      .min(0, t('errors.minValue', { minValue: 0 }))
      .required(t('errors.required')),
    categories: yup
      .array(
        yup.object({
          label: yup.string(),
          value: yup.number(),
        }),
      )
      .default([]),
    image: yup
      .mixed()
      .transform((files) => (files.length > 0 ? files[0] : null))
      .test('file-type', t('errors.fileFormat', { fileFormat: 'JPEG, JPG or PNG' }), (file) => {
        if (!file) return false;
        const fileExtension = file.name.split('.').at(-1);
        return ['png', 'jpg', 'jpeg'].includes(fileExtension.toLowerCase());
      })
      .test('file-max-size', t('errors.maxSize', { maxSize: '2 MBytes' }), (file) => {
        if (!file) return false;
        const fileSizeInBytes = file.size;
        return fileSizeInBytes / 1024 / 1024 <= 2;
      }),
  });
}
