import React from 'react';
import { Provider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import PropTypes from 'prop-types';
import AlertConfig from '../../config/alert.config';

const AlertProvider = ({ children }) => {
  return (
    <Provider template={AlertTemplate} {...AlertConfig}>
      {children}
    </Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node,
};

export default AlertProvider;
