import { Box, Card, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useAddTrainingSchema from './_hooks/useAddTrainingSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import axios from 'axios';
import useSingleSector from '../../hooks/useSingleSector';
import { sectors } from '../../constants';
import ApiConfig from '../../config/api.config';
import CustomSwitch from '../../components/forms/custom-elements/CustomSwitch';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import { isUndefined } from 'lodash';
import ErrorMessage from '../../components/ErrorMessage';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import i18next from 'i18next';
import { Navigate, useNavigate } from 'react-router';
import { useAuthentication } from '../../hooks';

const defaultValues = {
  StartDate: new Date(),
};
const AddTraining = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { tokenApi } = useAuthentication();
  const pageTitle = t('training.training');
  const schema = useAddTrainingSchema();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  let { sectors } = useSingleSector();
  sectors = sectors?.data?.map((cat) =>
    i18next.language === 'en'
      ? {
          label: cat?.title?.en,
          value: cat?.id,
        }
      : {
          label: cat?.title?.ar,
          value: cat?.id,
        },
  );

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/training',
      title: t('training.name'),
    },
    {
      title: pageTitle,
    },
  ];
  console.log(errors);
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });

      formData.append('title', headline);
      formData.append('description', description);

      formData.append('capacity', form.capacity);
      formData.append('status', form.isActive === true ? 1 : 0);
      formData.append('start_date', form.StartDate);
      formData.append('end_date', form.EndDate);
      formData.append('field', form.sectors);

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/trainings`,
        headers: { Authorization: 'Bearer ' + tokenApi },

        data: formData,
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/training');
    } catch (err) {
      Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
          />
          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue=""
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            errors={errors}
          />
          <CustomTextEditor
            defaultValue=""
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
          />
          {/* General Translation */}
          <Typography sx={{ marginTop: 2, paddingBottom: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>
          <CustomInput
            errors={errors}
            id="capacity"
            {...register('capacity')}
            label={t('common.capacity')}
            type="number"
            step=".01"
          />
          <CustomSelect id="sectors" label={t('common.sectors')} control={control} errors={errors}>
            {sectors?.map((item) => (
              <MenuItem key={item?.value} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </CustomSelect>
          <Box sx={{ mt: 3 }}>
            <Controller
              control={control}
              name="StartDate"
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={DateFnsAdapter}>
                  <DatePicker
                    minDate={new Date()}
                    label={t('common.startDate')}
                    fullWidth
                    onChange={(date, ...rest) => {
                      onChange(date, ...rest);
                    }}
                    value={value}
                    renderInput={(params) => (
                      <TextField {...params} error={!isUndefined(errors.StartDate)} />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Box>{' '}
          <Box sx={{ mt: 3 }}>
            <Controller
              control={control}
              name="EndDate"
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={DateFnsAdapter}>
                  <DatePicker
                    minDate={new Date()}
                    label={t('common.endDate')}
                    fullWidth
                    onChange={(date, ...rest) => {
                      onChange(date, ...rest);
                    }}
                    value={value}
                    renderInput={(params) => (
                      <TextField {...params} error={!isUndefined(errors.EndDate)} />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Box>
          <ErrorMessage error={errors.EndDate} msg={errors.EndDate?.message} />
          <Box>
            <CustomSwitch
              sx={{ mt: 61 }}
              id="isActive"
              label={t('common.active')}
              control={control}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default AddTraining;
