import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
const usePaymentSchema = (isEdit = false) => {
  const { t } = useTranslation();
  return yup.object({
    HeadlineAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(500, t('errors.maxLength', { maxLength: 500 })),
    HeadlineEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(500, t('errors.maxLength', { maxLength: 500 })),
    DescriptionAR: yup.string().required(t('errors.required')),
    DescriptionEN: yup.string().required(t('errors.required')),
    image: yup
      .mixed()
      .transform((files) => (files.length > 0 ? files[0] : null))
      .test('file-type', t('errors.fileFormat', { fileFormat: 'JPEG, JPG or PNG' }), (file) => {
        if (!file) return !isEdit ? false : true;
        const fileExtension = file.name.split('.').at(-1);
        return ['png', 'jpg', 'jpeg'].includes(fileExtension.toLowerCase());
      })
      .test('file-max-size', t('errors.maxSize', { maxSize: '2 MBytes' }), (file) => {
        if (!file) return !isEdit ? false : true;
        const fileSizeInBytes = file.size;
        return fileSizeInBytes / 1024 / 1024 <= 2;
      }),
  });
};

export default usePaymentSchema;
