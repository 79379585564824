import { Box, Card, Divider, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import { useAuthentication, useCategories } from '../../hooks';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useEditProductsSchema from './_hooks/useEditProductsSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import FileUpload from '../../components/FileUpload';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import useProducts from './_hooks/useProducts';
import { Navigate, useNavigate, useParams } from 'react-router';
import Spinner from '../spinner/Spinner';
import ImageView from '../../components/ImageView';
import CustomAutoComplete from '../../components/forms/custom-elements/CustomAutoComplete';
import i18next from 'i18next';
import CustomSwitch from '../../components/forms/custom-elements/CustomSwitch';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import useSingleSector from '../../hooks/useSingleSector';

const EditProducts = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pageTitle = t('products.editProducts');
  const schema = useEditProductsSchema();
  const [imageFile, setImageFile] = useState([]);
  const { tokenApi } = useAuthentication();

  const { products, isLoading: isProductsLoading } = useProducts(productId);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const defValues = [];
  let { categories } = useCategories();
  categories = categories?.data?.map((cat) =>
    i18next.language === 'en'
      ? {
          label: cat.name.en,
          value: cat.id,
        }
      : {
          label: cat.name.ar,
          value: cat.id,
        },
  );
  let { sectors } = useSingleSector();
  sectors = sectors?.data?.map((cat) =>
    i18next.language === 'en'
      ? {
          label: cat?.title?.en,
          value: cat?.id,
        }
      : {
          label: cat?.title?.ar,
          value: cat?.id,
        },
  );
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/products',
      title: t('products.name'),
    },
    {
      title: pageTitle,
    },
  ];

  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      imageFile.forEach((img, index) => {
        formData.append(`image_array[${index}]`, img);
      });

      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });
      const category = JSON.stringify(form.categories.map((cat) => cat.value));
      const field = JSON.stringify([form.sectors]);
      formData.append('field', field);

      formData.append('categories', category);
      formData.append('name', headline);
      formData.append('value', form.value);
      formData.append('units', form.units);
      formData.append('description', description);
      formData.append('is_active', form.isActive === true ? 1 : 0);
      if (form.image) {
        formData.append('main_image', form.image);
      }

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/products/${productId}?_method=put `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/products');
    } catch (err) {
      Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (isProductsLoading || !products) {
    return <Spinner />;
  }
  if (defValues.length === 0) {
    products?.product?.categories.map((cat) => {
      return i18next.language === 'en'
        ? defValues.push({ label: cat?.name.en, value: cat?.id })
        : defValues.push({ label: cat?.name.ar, value: cat?.id });
    });
  }
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
            defaultValue={products?.product?.name?.ar}
          />
          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue={products?.product?.description?.ar}
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            errors={errors}
            defaultValue={products?.product?.name?.en}
          />
          <CustomTextEditor
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue={products?.product?.description?.en}
          />
          {/* General Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>
          <CustomInput
            id="currentImage"
            label={t('common.currentImage')}
            errors={errors}
            {...register('currentImage')}
            defaultValue={products?.product?.main_image?.url}
            disabled
          />
          <CustomInput
            id="image"
            label={t('common.mainImage')}
            type="file"
            errors={errors}
            accept="image/png,image/jpeg,image/jpg"
            {...register('image')}
          />
          <Divider />
          <Typography sx={{ marginTop: 2 }} variant="h6">
            {t('common.currentGallery')}
          </Typography>
          <ImageView item={products?.product?.image_array} contentId={productId} name="products" />

          <CustomFormLabel>{t('common.gallery')}</CustomFormLabel>

          <FileUpload setImageFile={setImageFile} imageFile={imageFile} />
          <CustomInput
            errors={errors}
            id="value"
            {...register('value')}
            label={t('products.value')}
            type="number"
            step=".01"
            defaultValue={products?.product?.value}
          />
          <CustomInput
            errors={errors}
            id="units"
            {...register('units')}
            label={t('products.units')}
            type="number"
            step=".01"
            defaultValue={products?.product?.units}
          />
          <CustomAutoComplete
            control={control}
            defaultValue={defValues}
            errors={errors}
            id="categories"
            label={t('common.categories')}
            options={categories}
            multiple
          />
          <CustomSelect
            id="sectors"
            label={t('common.sectors')}
            errors={errors}
            control={control}
            defaultValue={products?.product?.field[0]?.id}
          >
            {sectors?.map((item) => (
              <MenuItem key={item?.value} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </CustomSelect>
          <CustomSwitch
            id="isActive"
            label={t('common.active')}
            control={control}
            defaultValue={products?.product?.is_active}
          />

          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default EditProducts;
