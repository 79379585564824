import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useAuthentication, useHttpHook } from '../../hooks';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomPaginatedTable from '../../components/tables/CustomPaginatedTable';
import RowEditButton from '../../components/tables/RowEditButton';
import { ApiMethods } from '../../services/api.service';
import i18next from 'i18next';
import useConfirmationDialoge from '../../hooks/useConfirmationDialoge';
import { Navigate } from 'react-router';

const Project = () => {
  const [project, setProject] = useState([]);
  const { t } = useTranslation();
  const { sendRequest } = useHttpHook();
  const { tokenApi } = useAuthentication();
  const { BootstrapDialog, BootstrapDialogTitle } = useConfirmationDialoge();
  const [projectId, setProjectId] = useState();
  const [open, setOpen] = React.useState(false);
  const alert = useAlert();

  const BCrumb = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      title: t('project.projectListing'),
    },
  ];
  const headCells = [
    {
      id: 'projectId',
      label: t('common.id'),
    },
    {
      id: 'ProjectName',
      label: t('common.name'),
    },
    {
      id: 'ProjectStatus',
      label: t('common.status'),
    },
    {
      id: 'ProjectLastUpdateTimestamp',
      label: t('common.lastUpdateTime'),
    },
  ];

  const handleDelete = async (id) => {
    try {
      await sendRequest({
        url: `api/projects/${id}`,
        method: ApiMethods.DELETE,
      });
      await alert.success(t('common.deletedSuccessfully'));
      setProject((prevState) => prevState?.filter(({ id: pid }) => id !== pid));

      setOpen(false);
    } catch {
      //
    }
  };
  const handleClickOpen = (id) => {
    setProjectId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <Box>
      <PageContainer title={t('project.projectListing')} description={t('project.bundlesListDesc')}>
        <Breadcrumb title={t('project.projectListing')} items={BCrumb} />
        <CustomPaginatedTable
          headCells={headCells}
          includeActions
          name="project"
          responseKey="project"
          setEntities={setProject}
          url="api/projects"
        >
          {project?.map(({ id, title, status, start_date }) => {
            return (
              <TableRow key={id}>
                <TableCell align="center" component="th" id={id} scope="row" padding="none">
                  {id}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" fontWeight="600">
                    {i18next.language === 'en' ? title.en : title.ar}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Box
                      sx={{
                        backgroundColor:
                          status === true
                            ? (theme) => theme.palette.success.main
                            : (theme) => theme.palette.error.main,
                        borderRadius: '100%',
                        height: '10px',
                        width: '10px',
                      }}
                    />
                    <TableCell align="center">
                      <Typography variant="h6" fontWeight="600">
                        {status === true ? 'active' : 'inActive'}
                      </Typography>
                    </TableCell>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" fontWeight="600">
                    {start_date}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <RowEditButton url={`/project/edit/${id}`} />

                  <Tooltip title={t('common.delete')}>
                    <IconButton onClick={() => handleClickOpen(id)}>
                      <FeatherIcon icon="trash" width="18" />
                    </IconButton>
                  </Tooltip>
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                      {t('common.deleteConfirmation')}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                      <Typography gutterBottom> {t('common.confirmation')}</Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={() => handleDelete(projectId)}>
                        {t('common.ok')}
                      </Button>
                      <Button onClick={handleClose}>{t('common.cancel')}</Button>
                    </DialogActions>
                  </BootstrapDialog>
                </TableCell>
              </TableRow>
            );
          })}
        </CustomPaginatedTable>
      </PageContainer>
    </Box>
  );
};

export default Project;
