import { LOGIN, LOGOUT } from '../constants';

export const login = (payload) => ({
  type: LOGIN,
  payload,
});

export const logout = () => ({
  type: LOGOUT,
});

export const checkTokenExpiration = () => {
  const expiresIn = JSON.parse(localStorage.getItem('expiresIn'));
  if (expiresIn && new Date(expiresIn).getTime() < new Date().getTime()) {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresIn');
  }
};
