import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SuccessStory } from '../../../constants';

export default function useEditSuccessSchema() {
  const { t } = useTranslation();

  return yup.object({
    status: yup
      .string()
      .oneOf(Object.values(SuccessStory), t('errors.required'))
      .required(t('errors.required')),
    HeadlineAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    HeadlineEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    DescriptionAR: yup.string().required(t('errors.required')),
    DescriptionEN: yup.string().required(t('errors.required')),
    categories: yup
      .array(
        yup.object({
          label: yup.string(),
          value: yup.number(),
        }),
      )
      .default([]),
    image: yup.mixed().transform((files) => (files.length > 0 ? files[0] : null)),
  });
}
