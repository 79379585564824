import { useEffect, useState } from 'react';

const useAuthentication = () => {
  const tokenApi = localStorage.getItem('token');
  const [isAuthorized, setIsAuthorized] = useState(false);
  useEffect(() => {
    if (tokenApi) {
      setIsAuthorized(true);
    }
  }, [tokenApi]);

  return {
    tokenApi,
    isAuthorized,
  };
};

export default useAuthentication;
