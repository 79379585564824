import { useEffect, useState } from 'react';
import { useHttpHook } from '../../../hooks';

export default function useTrainings(trainingId) {
  const { sendRequest, isLoading } = useHttpHook();
  const [training, setTraining] = useState();

  useEffect(() => {
    const fetchCategory = () =>
      sendRequest({
        url: `api/trainings/${trainingId}`,
      });

    (async () => {
      const response = await fetchCategory();
      if (response?.tr) {
        const { id, ...rest } = response?.tr;
        Object.assign(response, rest);
      }
      setTraining(response);
    })();
  }, []);
  return {
    isLoading,
    training,
  };
}
