import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
export default function useAddBranchSchema() {
  const { t } = useTranslation();

  return yup.object({
    nameAR: yup
      .string()
      .trim()
      .max(500, t('errors.maxLength', { maxLength: 500 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    nameEN: yup
      .string()
      .trim()
      .max(500, t('errors.maxLength', { maxLength: 500 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    country: yup.number().required(t('errors.required')),
    phone: yup
      .string()
      .length(11, t('errors.specificLength', { maxValue: 11 }))
      .required(t('errors.required'))
      .matches(/^\d+$/, t('errors.phone')),
  });
}
