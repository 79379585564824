import React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/container/PageContainer';
import CustomPaginatedTable from '../../components/tables/CustomPaginatedTable';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';

const PortalUsers = () => {
  const { t, i18n } = useTranslation();
  const bCrumb = [{ to: '/', title: t('home.homepage') }, { title: t('userslist.list') }];
  const [types, setTypes] = React.useState([]);

  const headCells = [
    {
      id: 'typeId',
      label: t('userslist.firstname'),
    },
    {
      id: 'paymentName',
      label: t('userslist.lastName'),
    },
    {
      id: 'typeLast',
      label: t('userslist.phoneNumber'),
    },
    {
      id: 'email',
      label: t('userslist.email'),
    },
  ];

  return (
    <div>
      <PageContainer title={t('userslist.list')}>
        <Breadcrumb title={t('userslist.list')} items={bCrumb} />
        <CustomPaginatedTable
          headCells={headCells}
          includeActions
          setEntities={setTypes}
          url="/api/users"
        >
          {types?.map(({ id, first_name, last_name, email, phone_number }, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow key={id}>
                <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                  {first_name}
                </TableCell>
                {/* content name label */}
                <TableCell align="center">{last_name}</TableCell>
                <TableCell align="center">
                  <Typography
                    color="textSecondary"
                    sx={{
                      fontSize: 'h6.fontSize',
                    }}
                  >
                    {phone_number}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color="textSecondary"
                    sx={{
                      fontSize: 'h6.fontSize',
                    }}
                  >
                    {email}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </CustomPaginatedTable>
      </PageContainer>
    </div>
  );
};

export default PortalUsers;
