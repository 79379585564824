import { useTranslation } from 'react-i18next';

const useSidebarItems = () => {
  const { t } = useTranslation();

  const items = [
    // {
    //   navlabel: true,
    //   subheader: t('users.user_other'),
    //   href: 'Users',
    // },

    // {
    //   title: t('users.usersList'),
    //   icon: 'users',
    //   href: '/users',
    // },
    // {
    //   navlabel: true,
    //   subheader: t('users.backOfficeuser_other'),
    //   href: 'Backofficeusers',
    // },
    // {
    //   title: t('users.addUser'),
    //   icon: 'user-plus',
    //   href: '/backofficeusers/add',
    // },

    // {
    //   title: t('users.BOusersList'),
    //   icon: 'users',
    //   href: '/backofficeusers',
    // },
    {
      navlabel: true,
      subheader: t('successStory.name'),
      href: 'SuccessStory',
    },
    {
      title: t('successStory.successStory'),
      icon: 'plus',
      href: '/successStory/add',
    },
    {
      title: t('successStory.successStoryListing'),
      icon: 'award',
      href: '/successStory',
    },
    {
      navlabel: true,
      subheader: t('event.name'),
      href: 'Events',
    },
    {
      title: t('event.events'),
      icon: 'plus',
      href: '/events/add',
    },
    {
      title: t('event.eventListing'),
      icon: 'bell',
      href: '/events',
    },

    {
      navlabel: true,
      subheader: t('news.name'),
      href: 'News',
    },
    {
      title: t('news.news'),
      icon: 'plus',
      href: '/news/add',
    },
    {
      title: t('news.newsListing'),
      icon: 'file-text',
      href: '/news',
    },
    {
      navlabel: true,
      subheader: t('causes.name'),
      href: 'Causes',
    },
    {
      title: t('causes.causes'),
      icon: 'plus',
      href: '/causes/add',
    },
    {
      title: t('causes.causesListing'),
      icon: 'activity',
      href: '/causes',
    },
    {
      navlabel: true,
      subheader: t('products.name'),
      href: 'products',
    },
    {
      title: t('products.products'),
      icon: 'plus',
      href: '/products/add',
    },
    {
      title: t('products.productsListing'),
      icon: 'heart',
      href: '/products',
    },
    {
      navlabel: true,
      subheader: t('project.name'),
      href: 'project',
    },
    {
      title: t('project.project'),
      icon: 'plus',
      href: '/project/add',
    },
    {
      title: t('project.projectListing'),
      icon: 'layers',
      href: '/project',
    },
    {
      navlabel: true,
      subheader: t('research.name'),
      href: 'research',
    },
    {
      title: t('research.research'),
      icon: 'plus',
      href: '/research/add',
    },
    {
      title: t('research.researchListing'),
      icon: 'search',
      href: '/research',
    },
    {
      navlabel: true,
      subheader: t('career.name'),
      href: 'career',
    },
    {
      title: t('career.career'),
      icon: 'plus',
      href: '/career/add',
    },
    {
      title: t('career.careerListing'),
      icon: 'briefcase',
      href: '/career',
    },
    {
      navlabel: true,
      subheader: t('training.name'),
      href: 'training',
    },
    {
      title: t('training.training'),
      icon: 'plus',
      href: '/training/add',
    },
    {
      title: t('training.trainingListing'),
      icon: 'book',
      href: '/training',
    },
    {
      navlabel: true,
      subheader: t('trustees.name'),
      href: 'trustees',
    },
    {
      title: t('trustees.trustees'),
      icon: 'plus',
      href: '/trustees/add',
    },
    {
      title: t('trustees.trusteesListing'),
      icon: 'book',
      href: '/trustees',
    },
    // {
    //   navlabel: true,
    //   subheader: t('commite.name'),
    //   href: 'commite',
    // },
    // {
    //   title: t('commite.commite'),
    //   icon: 'plus',
    //   href: '/commite/add',
    // },
    // {
    //   title: t('commite.commiteListing'),
    //   icon: 'book',
    //   href: '/commite',
    // },

    {
      navlabel: true,
      subheader: t('partners.name'),
      href: 'sectors',
    },
    {
      title: t('partners.partners'),
      icon: 'plus',
      href: '/partners/add',
    },
    {
      title: t('partners.partnersListing'),
      icon: 'pie-chart',
      href: '/partners',
    },
    {
      navlabel: true,
      subheader: t('sectors.name'),
      href: 'sectors',
    },
    {
      title: t('sectors.sectors'),
      icon: 'plus',
      href: '/sectors/add',
    },
    {
      title: t('sectors.sectorsListing'),
      icon: 'pie-chart',
      href: '/sectors',
    },
    {
      navlabel: true,
      subheader: t('categories.category_other'),
      href: 'Categories',
    },
    {
      title: t('categories.addCategory'),
      icon: 'plus',
      href: '/categories/add',
    },
    {
      title: t('categories.categoriesList'),
      icon: 'grid',
      href: '/categories',
    },
    {
      navlabel: true,
      subheader: t('branches.name'),
      href: 'branches',
    },
    {
      title: t('branches.addBranch'),
      icon: 'plus',
      href: '/branches/add',
    },
    {
      title: t('branches.name'),
      icon: 'git-branch',
      href: '/branches',
    },
    {
      navlabel: true,
      subheader: t('volunteer.name'),
      href: 'volunteer',
    },
    {
      title: t('volunteer.volunteerListing'),
      icon: 'star',
      href: '/volunteer',
    },
    {
      navlabel: true,
      subheader: t('supplier.name'),
      href: 'supplier',
    },
    {
      title: t('supplier.supplierListing'),
      icon: 'move',
      href: '/supplier',
    },
    {
      navlabel: true,
      subheader: t('delegation.name'),
      href: 'delegation',
    },
    {
      title: t('delegation.delegationListing'),
      icon: 'external-link',
      href: '/delegation',
    },

    {
      navlabel: true,
      subheader: t('inKind.name'),
      href: 'inKind',
    },
    {
      title: t('inKind.inKindListing'),
      icon: 'book',
      href: '/inKind',
    },
    {
      navlabel: true,
      subheader: t('content.title'),
      href: 'content',
    },
    {
      title: t('content.addType'),
      icon: 'plus',
      href: '/content/addtype',
    },
    {
      title: t('content.typeList'),
      icon: 'book',
      href: '/content/contenytypelist',
    },
    {
      title: t('content.addContent'),
      icon: 'plus',
      href: '/content/addcontent',
    },
    {
      title: t('content.listContent'),
      icon: 'book',
      href: '/content/contentlist',
    },
    {
      navlabel: true,
      subheader: t('payment.title'),
      href: 'content',
    },
    {
      title: t('payment.addMethod'),
      icon: 'plus',
      href: '/payment/addpayment',
    },
    {
      title: t('payment.paymentList'),
      icon: 'book',
      href: '/payment/paymentlist',
    },
    {
      navlabel: true,
      subheader: t('banks.title'),
      href: 'content',
    },
    {
      title: t('banks.list'),
      icon: 'book',
      href: '/banks/banksList',
    },
    {
      title: t('banks.add'),
      icon: 'plus',
      href: '/banks/addBank',
    },
    {
      navlabel: true,
      subheader: t('userslist.title'),
      href: 'content',
    },
    {
      title: t('userslist.list'),
      icon: 'book',
      href: '/users/usersList',
    },
    {
      navlabel: true,
      subheader: t('userslist.backUsers'),
      href: 'content',
    },
    {
      title: t('userslist.backUsersList'),
      icon: 'book',
      href: '/users/backoffice',
    },
    {
      title: t('userslist.addbackUsers'),
      icon: 'plus',
      href: '/users/addbackoffice',
    },
  ];
  return { items };
};

export default useSidebarItems;
