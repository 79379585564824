import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

const ErrorMessage = ({ error, msg }) => {
  return (
    <div className="ml-1 mt-1">
      {error && (
        <Typography variant="body1" sx={{ color: (theme) => theme.palette.error.main }}>
          {msg}
        </Typography>
      )}
    </div>
  );
};
ErrorMessage.propTypes = {
  error: PropTypes.any,
  msg: PropTypes.string,
};

export default ErrorMessage;
