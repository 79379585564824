import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { Box, Card, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import useAddCategorySchema from './_hooks/useAddCategorySchema';
import PageContainer from '../../components/container/PageContainer';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import { Navigate, useNavigate } from 'react-router';
import { useAuthentication } from '../../hooks';

// TODO: check why is exception thrown in console after submitting category with languages
const AddCategory = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const { tokenApi } = useAuthentication();

  const [loading, setLoading] = useState(false);

  const addCategorySchema = useAddCategorySchema();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(addCategorySchema),
  });
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      const name = JSON.stringify({
        en: form.nameEN,
        ar: form.nameAR,
      });

      formData.append('name', name);
      if (form.image) {
        formData.append('image', form.image);
      }
      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/categories `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/categories');
    } catch (err) {
      console.log(err, 'error');
      setLoading(false);
    }
  };

  const BCrumb = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      title: t('categories.addCategory'),
    },
  ];
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <Box>
      <PageContainer
        title={t('categories.addCategory')}
        description={t('categories.addCategoryDesc')}
      >
        <Breadcrumb title={t('categories.addCategory')} items={BCrumb} />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.arabicSection')}
            </Typography>
            <CustomInput
              id="nameAR"
              label={t('common.name')}
              errors={errors}
              {...register('nameAR')}
            />
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.englishSection')}
            </Typography>
            <CustomInput
              id="nameEN"
              label={t('common.name')}
              errors={errors}
              {...register('nameEN')}
            />
            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.generalSection')}
            </Typography>
            <CustomInput
              id="image"
              errors={errors}
              {...register('image')}
              label={t('common.mainImage')}
              type="file"
              accept="image/png,image/jpeg,image/jpg"
            />

            <Box sx={{ mt: 2 }}>
              <LoadingButton type="submit" variant="contained" loading={loading}>
                {t('common.submit')}
              </LoadingButton>
            </Box>
          </form>
        </Card>
      </PageContainer>
    </Box>
  );
};

export default AddCategory;
