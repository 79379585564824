import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

const VideoUpload = ({ videoFile, setVideoFile }) => {
  const fileParams = () => {
    return { url: 'https://httpbin.org/post' };
  };
  const onFileChange = ({ file }, status) => {
    if (status === 'done') {
      setVideoFile((prev) => [...prev, file]);
    } else if (status === 'removed') {
      setVideoFile(videoFile.filter((f) => f.name !== file.name));
    }
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <Dropzone
      onChangeStatus={onFileChange}
      getUploadParams={fileParams}
      getFilesFromEvent={getFilesFromEvent}
      accept="video/*"
      maxFiles={10}
      inputContent="Drop A File"
      styles={{
        dropzone: { width: '100%', height: 400, overflow: 'hidden', marginBlock: '15px' },
        dropzoneActive: { borderColor: 'green' },
      }}
    />
  );
};
export default VideoUpload;
