import { Box, Card, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm, Controller } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useAddResearchSchema from './_hooks/useAddResearchSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import { Sources } from '../../constants';
import CustomSwitch from '../../components/forms/custom-elements/CustomSwitch';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import ErrorMessage from '../../components/ErrorMessage';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import ReportUpload from '../../components/ReportUpload';
import { Navigate, useNavigate } from 'react-router';
import { useAuthentication } from '../../hooks';

const defaultValues = {
  eventDate: new Date(),
  sources: Sources.AlMasry,
};

const AddResearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageTitle = t('research.research');
  const { tokenApi } = useAuthentication();
  const schema = useAddResearchSchema();
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/events',
      title: t('research.name'),
    },
    {
      title: pageTitle,
    },
  ];

  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      if (report.length >= 1) {
        report.forEach((img, index) => {
          formData.append(`attachments[${index}]`, img);
        });
      }

      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });
      formData.append('title', headline);
      formData.append('description', description);
      formData.append('status', form.isActive === true ? 'active' : 'inactive');
      formData.append('source', form.sources);
      formData.append('date', new Date(form.eventDate).getFullYear());

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/researches `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/research');
    } catch (err) {
      Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
          />
          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue=""
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            errors={errors}
          />
          <CustomTextEditor
            defaultValue=""
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
          />
          {/* General Translation */}

          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>

          <CustomFormLabel>{t('common.reports')}</CustomFormLabel>

          <ReportUpload setReport={setReport} report={report} />

          <CustomSelect id="sources" label={t('common.sources')} control={control}>
            {Object.keys(Sources).map((key) => (
              <MenuItem key={key} value={Sources[key]}>
                {key}
              </MenuItem>
            ))}
          </CustomSelect>

          <Box sx={{ mt: 3 }}>
            <Controller
              control={control}
              name="eventDate"
              render={({ field: { onChange, value } }) => {
                return (
                  <LocalizationProvider dateAdapter={DateFnsAdapter}>
                    <DatePicker
                      views={['year']}
                      label={t('common.date')}
                      fullWidth
                      onChange={(date, ...rest) => {
                        onChange(date, ...rest);
                      }}
                      value={value}
                      renderInput={(params) => (
                        <TextField {...params} error={!isUndefined(errors.eventDate)} />
                      )}
                    />
                  </LocalizationProvider>
                );
              }}
            />
          </Box>
          <ErrorMessage error={errors.eventDate} msg={errors.eventDate?.message} />
          <CustomSwitch id="isActive" label={t('common.active')} control={control} />

          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default AddResearch;
