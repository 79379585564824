import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
const usePartnerSchema = () => {
  const { t } = useTranslation();
  return yup.object({
    HeadlineAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(500, t('errors.maxLength', { maxLength: 500 })),
    HeadlineEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(500, t('errors.maxLength', { maxLength: 500 })),
    DescriptionAR: yup.string().required(t('errors.required')),
    DescriptionEN: yup.string().required(t('errors.required')),
    image: yup.mixed().transform((files) => (files.length > 0 ? files[0] : null)),
  });
};

export default usePartnerSchema;
