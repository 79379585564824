import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
export default function useEditCategorySchema() {
  const { t } = useTranslation();

  return yup.object({
    nameAR: yup
      .string()
      .trim()
      .max(500, t('errors.maxLength', { maxLength: 500 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    nameEN: yup
      .string()
      .trim()
      .max(500, t('errors.maxLength', { maxLength: 500 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    image: yup.mixed().transform((files) => (files.length > 0 ? files[0] : null)),
  });
}
