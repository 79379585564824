import { Box, Card, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useDelegationSchema from './_hooks/useDelegationSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import { DelegateStatus } from '../../constants';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import { Navigate, useNavigate, useParams } from 'react-router';
import Spinner from '../spinner/Spinner';
import useDelegations from './_hooks/useDelegations';
import { useAuthentication } from '../../hooks';

const EditDelegations = () => {
  const { delegationId } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const pageTitle = t('delegation.editDelegation');
  const { tokenApi } = useAuthentication();

  const schema = useDelegationSchema();
  const { delegation, isLoading: isDelegationLoading } = useDelegations(delegationId);

  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  console.log(delegation);
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/delegation',
      title: t('delegation.name'),
    },
    {
      title: pageTitle,
    },
  ];

  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append('note', form.Note);
      formData.append('status', form.status);

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/donations/${delegationId}?_method=put `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/delegation');
    } catch (err) {
      console.log(err, 'error');
      setLoading(false);
    }
  };
  if (isDelegationLoading || !delegation) {
    return <Spinner />;
  }
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            id="FirstName"
            label={t('common.firstname')}
            {...register('FirstName')}
            errors={errors}
            defaultValue={delegation?.first_name}
            disabled
          />
          <CustomInput
            id="LastName"
            label={t('common.lastName')}
            {...register('LastName')}
            errors={errors}
            defaultValue={delegation?.last_name}
            disabled
          />
          <CustomInput
            id="PhoneNumber"
            label={t('common.phoneNumber')}
            {...register('PhoneNumber')}
            errors={errors}
            defaultValue={delegation?.phone_number}
            disabled
          />
          <CustomInput
            id="Email"
            label={t('common.email')}
            {...register('Email')}
            errors={errors}
            defaultValue={delegation?.email}
            disabled
          />
          <CustomInput
            id="City"
            label={t('common.city')}
            {...register('City')}
            errors={errors}
            defaultValue={delegation?.city}
            disabled
          />
          <CustomInput
            id="Address"
            label={t('common.address')}
            {...register('Address')}
            errors={errors}
            defaultValue={delegation?.address}
            disabled
          />
          <CustomInput
            id="Amount"
            label={t('common.amount')}
            {...register('Amount')}
            errors={errors}
            defaultValue={delegation?.donation_amount}
            disabled
          />
          <CustomInput
            id="Type"
            label={t('common.type')}
            {...register('Type')}
            errors={errors}
            defaultValue={delegation?.donation_type}
            disabled
          />

          <CustomTextEditor
            id="Note"
            label={t('common.note')}
            control={control}
            errors={errors}
            defaultValue={delegation?.note}
          />
          <CustomSelect
            id="status"
            label={t('common.status')}
            control={control}
            defaultValue={delegation?.status}
          >
            {Object.keys(DelegateStatus).map((key) => (
              <MenuItem key={key} value={DelegateStatus[key]}>
                {key}
              </MenuItem>
            ))}
          </CustomSelect>

          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default EditDelegations;
