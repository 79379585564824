import { Box, Grid } from '@mui/material';
import React from 'react';

const AttachView = ({ item }) => {
  return (
    <>
      <Box
        style={{
          border: '1px solid lightgrey',
          borderRadius: '5px',
          padding: '30px',
          marginTop: '20px',
        }}
        sx={{ flexGrow: 1 }}
      >
        <Grid container spacing={8}>
          {item?.map((ite) => (
            <Grid sx={{ position: 'relative' }} item xs={12} key={ite.id} md={12} lg={12}>
              <a
                style={{
                  border: '2px solid lightgrey',
                  borderRadius: '9px',
                  fontSize: '13px',
                  padding: '12px',
                  textDecoration: 'none',
                  paddingBlock: '20px',
                  width: '100px',
                  height: '100px',
                }}
                href={ite?.url}
              >
                {ite?.url.split('//')[1].split('/')[3]}
              </a>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default AttachView;
