import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomPaginatedTable from '../../components/tables/CustomPaginatedTable';
import RowEditButton from '../../components/tables/RowEditButton';
import { useParams } from 'react-router';

const CareerApplicants = () => {
  const [applicants, setApplicants] = useState([]);
  const { jobId } = useParams();
  const { t } = useTranslation();

  const BCrumb = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/career',
      title: t('career.name'),
    },
    {
      title: t('applicant.applicantListing'),
    },
  ];
  const headCells = [
    {
      id: 'jobId',
      label: t('common.id'),
    },
    {
      id: 'CareerHeadline',
      label: t('common.name'),
    },
    {
      id: 'CareerHeadline',
      label: t('common.phoneNumber'),
    },
    {
      id: 'Careerstatus',
      label: t('common.status'),
    },
  ];

  return (
    <Box>
      <PageContainer
        title={t('applicant.applicantListing')}
        description={t('applicant.bundlesListDesc')}
      >
        <Breadcrumb title={t('applicant.applicantListing')} items={BCrumb} />
        <CustomPaginatedTable
          headCells={headCells}
          includeActions
          name="Applicants"
          responseKey="Applicants"
          setEntities={setApplicants}
          url={`api/jobs?filter[career]=${jobId}`}
        >
          {applicants?.map(({ id, name, phone, status }) => {
            return (
              <TableRow key={id}>
                <TableCell align="center" component="th" id={id} scope="row" padding="none">
                  {id}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" fontWeight="600">
                    {name}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color="textSecondary"
                    sx={{
                      fontSize: 'h6.fontSize',
                    }}
                  >
                    {phone}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Box />
                    <TableCell align="center">
                      <Typography variant="h6" fontWeight="600">
                        {status}
                      </Typography>
                    </TableCell>
                  </Box>
                </TableCell>

                <TableCell align="center">
                  <RowEditButton url={`/career/applicants/edit/${id}`} />
                </TableCell>
              </TableRow>
            );
          })}
        </CustomPaginatedTable>
      </PageContainer>
    </Box>
  );
};

export default CareerApplicants;
