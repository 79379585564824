import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
export default function useAddCategorySchema() {
  const { t } = useTranslation();

  return yup.object({
    nameAR: yup
      .string()
      .trim()
      .max(500, t('errors.maxLength', { maxLength: 500 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    nameEN: yup
      .string()
      .trim()
      .max(500, t('errors.maxLength', { maxLength: 500 }))
      .min(2, t('errors.minLength', { minLength: 2 }))
      .required(t('errors.required')),
    image: yup
      .mixed()
      .transform((files) => (files.length > 0 ? files[0] : null))
      .test('file-type', t('errors.fileFormat', { fileFormat: 'JPEG, JPG or PNG' }), (file) => {
        if (!file) return false;
        const fileExtension = file.name.split('.').at(-1);
        return ['png', 'jpg', 'jpeg'].includes(fileExtension.toLowerCase());
      })
      .test('file-max-size', t('errors.maxSize', { maxSize: '2 MBytes' }), (file) => {
        if (!file) return false;
        const fileSizeInBytes = file.size;
        return fileSizeInBytes / 1024 / 1024 <= 2;
      }),
  });
}
