import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export default function useAddTrainingSchema() {
  const { t } = useTranslation();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() + 1);

  return yup.object({
    isActive: yup.bool().default(false).required(t('errors.required')),

    DescriptionAR: yup.string().required(t('errors.required')),
    DescriptionEN: yup.string().required(t('errors.required')),
    HeadlineAR: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    HeadlineEN: yup
      .string()
      .min(5, t('errors.minLength', { minLength: 5 }))
      .max(50, t('errors.maxLength', { maxLength: 50 }))
      .required('errors.required'),
    StartDate: yup.date().required('Start datetime is required'),
    sectors: yup.string().required(t('errors.required')),
    capacity: yup
      .number()
      .integer()
      .transform((value) => (Number.isNaN(value) ? -1 : value))
      .min(0, t('errors.minValue', { minValue: 0 }))
      .required(t('errors.required')),
    EndDate: yup
      .date()
      .when('StartDate', (StartDate, schema) => {
        if (StartDate) {
          const currentDay = new Date(StartDate.getTime() + 86400000);
          return schema.min(currentDay, t('common.dateError'));
        } else {
          return schema;
        }
      })
      .required(t('common.dateError')),
  });
}
