import React, { useEffect, useState } from 'react';
import { useHttpHook } from '../../../hooks';

const usePayment = (methodId) => {
  const { sendRequest, isLoading } = useHttpHook();
  const [paymentMethod, setPaymentMethod] = useState(null);
  useEffect(() => {
    const fetchContentTypes = () =>
      sendRequest({
        url: `api/payments/${methodId}`,
      });

    (async () => {
      const response = await fetchContentTypes();
      if (response?.tr) {
        const { id, ...rest } = response?.tr;
        Object.assign(response, rest);
      }
      setPaymentMethod(response);
    })();
  }, []);

  return {
    isLoading,
    paymentMethod,
  };
};

export default usePayment;
