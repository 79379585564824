import { useEffect, useState } from 'react';
import { useHttpHook } from '../../../hooks';

export default function useBranches(categoryId) {
  const { sendRequest, isLoading } = useHttpHook();
  const [branch, setBranch] = useState([]);

  useEffect(() => {
    const fetchCategory = () =>
      sendRequest({
        url: `api/cities/${categoryId}`,
      });

    (async () => {
      const response = await fetchCategory();
      if (response?.tr) {
        const { id, ...rest } = response?.tr;
        Object.assign(response, rest);
      }
      setBranch(response);
    })();
  }, []);

  return {
    isLoading,
    branch,
  };
}
