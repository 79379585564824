import { Box, Card, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import { SketchPicker } from 'react-color';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useAddSectorsSchema from './_hooks/useAddSectorsSchema';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import CustomSwitch from '../../components/forms/custom-elements/CustomSwitch';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import ErrorMessage from '../../components/ErrorMessage';
import { Navigate, useNavigate } from 'react-router';
import { useAuthentication } from '../../hooks';

const AddSectors = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageTitle = t('sectors.sectors');
  const { tokenApi } = useAuthentication();

  const schema = useAddSectorsSchema();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const [clr, setColor] = useState();
  const [colorError, setColorError] = useState();

  const {
    formState: { errors },
    register,
    handleSubmit,

    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/sectors',
      title: t('sectors.name'),
    },
    {
      title: pageTitle,
    },
  ];
  console.log(errors);
  const onSubmit = async (form) => {
    if (clr) {
      setColorError(false);

      try {
        setLoading(true);
        const formData = new FormData();

        const description = JSON.stringify({
          en: form.DescriptionEN,
          ar: form.DescriptionAR,
        });
        const headline = JSON.stringify({
          en: form.HeadlineEN,
          ar: form.HeadlineAR,
        });
        formData.append('title', headline);

        formData.append('description', description);
        formData.append('status', form.isActive === true ? 1 : 0);
        formData.append('icon', form.image);
        formData.append(
          'color',
          `rgba(${clr?.rgb?.r},${clr?.rgb?.g},${clr?.rgb?.b},${clr?.rgb?.a})`,
        );
        await axios({
          method: 'POST',
          url: `${ApiConfig.baseURL}/api/fields `,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
        });
        alert.success(t('common.addedSuccessfully'));
        setLoading(false);
        navigate('/sectors');
      } catch (err) {
        Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
        setLoading(false);
      }
    } else {
      setColorError(true);
    }
  };

  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
          />

          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue=""
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            errors={errors}
          />

          <CustomTextEditor
            defaultValue=""
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
          />
          {/* General Translation */}

          <Typography sx={{ marginTop: 2, paddingBottom: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>
          <SketchPicker id="color" name="color" color={clr} onChange={(color) => setColor(color)} />
          {colorError ? (
            <ErrorMessage error={t('errors.required')} msg={t('errors.required')} />
          ) : (
            ''
          )}
          <Box sx={{ mt: 4 }}>
            <CustomInput
              id="image"
              label={t('common.mainImage')}
              type="file"
              errors={errors}
              accept="image/png,image/jpeg,image/jpg"
              {...register('image')}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <CustomSwitch
              sx={{ mt: 61 }}
              id="isActive"
              label={t('common.active')}
              control={control}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default AddSectors;
