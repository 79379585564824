import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/container/PageContainer';
import CustomPaginatedTable from '../../components/tables/CustomPaginatedTable';
import RowEditButton from '../../components/tables/RowEditButton';
import { useHttpHook } from '../../hooks';
import useConfirmationDialoge from '../../hooks/useConfirmationDialoge';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import { ApiMethods } from '../../services/api.service';
import FeatherIcon from 'feather-icons-react';

const ContentList = () => {
  const { t, i18n } = useTranslation();
  const bCrumb = [{ to: '/', title: t('home.homepage') }, { title: t('content.listContent') }];
  const [types, setTypes] = React.useState([]);
  const [categoryId, setCategoryId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const { BootstrapDialog, BootstrapDialogTitle } = useConfirmationDialoge();
  const alert = useAlert();
  const { sendRequest } = useHttpHook();

  const headCells = [
    {
      id: 'typeId',
      label: t('common.id'),
    },
    {
      id: 'typeName',
      label: t('content.title'),
    },
    {
      id: 'typeName',
      label: t('content.typeName'),
    },
    {
      id: 'typeLast',
      label: t('common.lastUpdateTime'),
    },
  ];
  const handleClickOpen = (id) => {
    setCategoryId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async (id) => {
    try {
      await sendRequest({
        url: `api/contents/items/${id}`,
        method: ApiMethods.DELETE,
      });
      await alert.success(t('common.deletedSuccessfully'));
      setTypes((prevState) => prevState?.filter(({ id: pid }) => id !== pid));

      setOpen(false);
    } catch {
      //
    }
  };
  return (
    <div>
      <PageContainer title={t('content.listContent')}>
        <Breadcrumb title={t('content.listContent')} items={bCrumb} />
        <CustomPaginatedTable
          headCells={headCells}
          includeActions
          setEntities={setTypes}
          url="api/contents/items"
        >
          {types?.map(({ id, title, slug, updated_at }, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow key={id}>
                <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                  {id}
                </TableCell>
                {/* content name label */}
                <TableCell align="center">
                  {i18n.language === 'en' ? title?.en : title?.ar}
                </TableCell>
                {/* content type label */}
                <TableCell align="center">{slug}</TableCell>
                <TableCell align="center">
                  <Typography
                    color="textSecondary"
                    sx={{
                      fontSize: 'h6.fontSize',
                    }}
                  >
                    {updated_at}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <RowEditButton url={`/content/contnetitem/edit/${id}`} />

                  <Tooltip title={t('common.delete')}>
                    <IconButton onClick={() => handleClickOpen(id)}>
                      <FeatherIcon icon="trash" width="18" />
                    </IconButton>
                  </Tooltip>
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                      {t('branches.deleteBranch')}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                      <Typography gutterBottom>{t('common.confirmation')}</Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={() => handleDelete(categoryId)}>
                        {t('common.ok')}
                      </Button>
                      <Button onClick={handleClose}>{t('common.cancel')}</Button>
                    </DialogActions>
                  </BootstrapDialog>
                </TableCell>
              </TableRow>
            );
          })}
        </CustomPaginatedTable>
      </PageContainer>
    </div>
  );
};

export default ContentList;
