import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import Purchases from '../../components/Purchases';
import ApiConfig from '../../config/api.config';
import { useAuthentication } from '../../hooks';
import Spinner from '../spinner/Spinner';

const Home = () => {
  const { tokenApi } = useAuthentication();
  const [loading, setLoading] = React.useState(false);
  const [statistics, setstatistics] = React.useState({});

  const { t } = useTranslation();
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${ApiConfig.baseURL}/api/statistics`,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
    })
      .then((data) => {
        setLoading(false);
        setstatistics(data?.data);
        console.log('this is statistics', data);
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }

  if (loading || !statistics) {
    return <Spinner />;
  }
  return (
    <div>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12} lg={4} md={4}>
            <Purchases
              statistics={statistics?.users?.portal}
              name={t('statistics.users')}
              icon={'user'}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4} md={4}>
            <Purchases
              statistics={
                statistics?.donation?.sadaka?.New +
                statistics?.donation?.sadaka?.Contacted +
                statistics?.donation?.sadaka?.Collected +
                statistics?.donation?.zakat?.New +
                statistics?.donation?.zakat?.Contacted +
                statistics?.donation?.zakat?.Collected
              }
              name={t('statistics.donors')}
              icon={'user'}
            />{' '}
          </Grid>
          <Grid item sm={6} xs={12} lg={4} md={4}>
            <Purchases
              statistics={
                statistics?.suppliers?.new +
                statistics?.suppliers?.contacted +
                statistics?.suppliers?.accepted +
                statistics?.suppliers?.rejected
              }
              name={t('statistics.suppliers')}
              icon={'user'}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4} md={4}>
            <Purchases
              statistics={
                statistics?.volunteers?.new +
                statistics?.volunteers?.contacted +
                statistics?.volunteers?.accepted +
                statistics?.volunteers?.rejected
              }
              name={t('statistics.volunteers')}
              icon={'user'}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4} md={4}>
            <Purchases
              statistics={statistics?.news?.active}
              name={t('statistics.newsnumber')}
              icon={'user'}
            />
          </Grid>
          <Grid item sm={6} xs={12} lg={4} md={4}>
            <Purchases
              statistics={statistics?.events?.active}
              name={t('statistics.eventssnumber')}
              icon={'user'}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
