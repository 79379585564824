import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { Box, Card, Typography, MenuItem, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import useEditBranchScheme from './_hooks/useEditBranchScheme';
import PageContainer from '../../components/container/PageContainer';
import { Navigate, useNavigate, useParams } from 'react-router';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import useBranches from './_hooks/useBranches';
import useCountries from './_hooks/useCountries';
import Spinner from '../spinner/Spinner';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import Map from '../../components/map/map';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import { useAuthentication } from '../../hooks';

const EditBranches = () => {
  const { branchId } = useParams();
  const navigate = useNavigate();
  const { branch, isLoading: isBranchLoading } = useBranches(branchId);
  const { countries, isLoading: isCountriesLoading } = useCountries();
  const { tokenApi } = useAuthentication();
  const [selectedGeo, setSelectedGeo] = useState({});
  const [location, setLocation] = useState(null);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const alert = useAlert();
  const editBranchScheme = useEditBranchScheme();

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    control,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(editBranchScheme),
    defaultValues: {
      country: branch?.country?.id,
    },
  });

  useEffect(() => {
    reset({
      country: branch?.country?.id,
    });
  }, [branch]);
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = {};

      const name = JSON.stringify({
        en: form.nameEN,
        ar: form.nameAR,
      });

      formData.name = JSON.parse(name);
      formData.branch_location = location || branch?.branch_location;
      formData.latitude = selectedGeo.lat;
      formData.longitude = selectedGeo.lng;
      formData.country = form.country;
      formData.phone = `+20${form.phone}`;
      await axios({
        method: 'Put',
        url: `${ApiConfig.baseURL}/api/cities/${branchId}`,
        headers: { Authorization: 'Bearer ' + tokenApi },

        data: formData,
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/branches');
    } catch (err) {
      Object.values(err.response.data.errors).map((errorMessage) => alert.error(errorMessage));
      setLoading(false);
    }
  };
  const BCrumb = [
    {
      title: t('home.homepage'),
      to: '/',
    },
    {
      title: t('branches.name'),
      to: '/branches',
    },
    {
      title: t('branches.editBranch'),
    },
  ];
  if (isBranchLoading || isCountriesLoading || branch.length === 0) {
    return <Spinner />;
  }
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <Box>
      <PageContainer title={t('branches.editBranch')} description={t('branches.editBranchDesc')}>
        <Breadcrumb title={t('branches.editBranch')} items={BCrumb} />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            {branch?.name && (
              <>
                <Typography sx={{ marginTop: 2 }} variant="h2">
                  {t('common.arabicSection')}
                </Typography>
                <CustomInput
                  id="nameAR"
                  label={t('common.name')}
                  errors={errors}
                  {...register('nameAR')}
                  defaultValue={branch?.name.ar || ''}
                />
              </>
            )}

            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.englishSection')}
            </Typography>
            <CustomInput
              id="nameEN"
              label={t('common.name')}
              errors={errors}
              {...register('nameEN')}
              defaultValue={branch?.name.en || ''}
            />

            <Typography sx={{ marginTop: 2 }} variant="h2">
              {t('common.generalSection')}
            </Typography>

            <CustomSelect
              id="country"
              label={t('common.city')}
              control={control}
              defaultValue={branch?.country?.id}
              externalOnChange={(e) => setValue('country', e.target.value)}
            >
              {countries?.map((country, key) => (
                <MenuItem key={key} value={country.id}>
                  {country?.name[i18n.language]}
                </MenuItem>
              ))}
            </CustomSelect>

            <CustomInput
              id="phone"
              label={t('common.phoneNumber')}
              errors={errors}
              {...register('phone')}
              defaultValue={branch?.phone || ''}
            />

            <Map
              lat={branch.latitude}
              lng={branch.longitude}
              zoom={10}
              setSelected={setSelectedGeo}
              selected={selectedGeo}
              setLocation={setLocation}
              location={branch.branch_location}
            />
            <SubmitButton isLoading={loading} />
          </form>
        </Card>
      </PageContainer>
    </Box>
  );
};

export default EditBranches;
