import axios from 'axios';
import ApiConfig from '../config/api.config';

const axiosInstance = axios.create({ ...ApiConfig });
axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use((originalConfig) => {
  return originalConfig;
});

axiosInstance.interceptors.response.use(
  (res) => res.data,
  async (err) => {
    return Promise.reject(err);
  },
);

export default axiosInstance;

export const ApiMethods = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH',
});

export const ApiErrors = Object.freeze({
  UNAUTHORIZED: 401,
});
