import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DelegateStatus } from '../../../constants';

export default function useInKindSchema() {
  const { t } = useTranslation();

  return yup.object({
    status: yup
      .string()
      .oneOf(Object.values(DelegateStatus), t('errors.required'))
      .required(t('errors.required')),
  });
}
