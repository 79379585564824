export const getInitials = (name) => {
  const [firstName, secondName] = typeof name === 'string' ? name.split(/\s/) : [];
  let res = '';
  if (firstName) {
    res += firstName[0].toUpperCase();
  }
  if (secondName) {
    res += secondName[0].toUpperCase();
  }

  return res;
};

export const f = () => {};
