import React from 'react';
import { useAlert } from 'react-alert';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useAuthentication } from '../../hooks';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import ApiConfig from '../../config/api.config';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import { Box, Card, CircularProgress, Typography } from '@mui/material';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import useSingleType from './_hooks/useSigleType';

const EditContentTypes = () => {
  const { typeId } = useParams();
  const navigate = useNavigate();
  const { contentTypes, isLoading } = useSingleType(typeId);
  const { tokenApi } = useAuthentication();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const bCrumb = [{ to: '/', title: t('home.homepage') }, { title: t('content.editContnetType') }];
  const editeSchema = yup.object({
    contentType: yup.string(),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(editeSchema),
    defaultValues: {
      contentType: contentTypes?.slug,
    },
  });
  const handleAddingType = async (form) => {
    try {
      setLoading(true);
      const headline = form.contentType;
      // formData.append('slug', headline);
      await axios({
        method: 'PUT',
        url: `${ApiConfig.baseURL}/api/contents/groups/${typeId} `,
        data: { slug: headline },
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/content/contenytypelist');
    } catch (err) {
      // alert?.error(err?.response?.data?.data?.message);
      Object.values(err?.response?.data?.data)?.map((i) => alert.error(i));

      setLoading(false);
    }
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  } else if (loading || !contentTypes) {
    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />;
      </Box>
    );
  }
  return (
    <div>
      <PageContainer title={t('content.addType')}>
        <Breadcrumb title={t('content.addType')} items={bCrumb} />
        <Card>
          <Typography variant="h2" sx={{ marginTop: 2 }}>
            {t(t('content.addType'))}
          </Typography>
          <form onSubmit={handleSubmit(handleAddingType)}>
            <CustomInput
              id="contentType"
              label={t('common.name')}
              errors={errors}
              {...register('contentType')}
              defaultValue={contentTypes.slug}
            />
            <SubmitButton isLoading={loading} sx={{ marginTop: 2 }} />
          </form>
        </Card>
      </PageContainer>
    </div>
  );
};

export default EditContentTypes;
