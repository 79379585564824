import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router';
import PageContainer from '../../components/container/PageContainer';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import { useAuthentication } from '../../hooks';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import useSingleSector from '../../hooks/useSingleSector';
import i18next from 'i18next';
import useUserScema from './useUserScema';

const AddBackOfficeUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { tokenApi, isAuthorized } = useAuthentication();
  const [loading, setLoading] = React.useState(false);
  const schema = useUserScema();
  const alert = useAlert();
  let { sectors } = useSingleSector();
  //   let { currencies } = useSinglecurrencies();
  const bCrumb = [{ to: '/', title: t('home.homepage') }, { title: t('banks.add') }];
  const defaultValues = {};
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });
  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = { email: form.email, password: form.password };
      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/users  `,
        data: formData,
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + tokenApi },
      }).then(() => {
        navigate('/users/backoffice');
        alert.success(t('common.addedSuccessfully'));
        setLoading(false);
      });
    } catch (err) {
      console.log(err.data);
      // alert?.error(err?.response?.data?.data?.message);
      // Object.values(err?.response?.data?.data)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={t('banks.add')}>
      <Breadcrumb title={t('banks.add')} items={bCrumb} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('userslist.addbackUsers')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('userslist.userName')}
            {...register('email')}
            errors={errors}
          />

          <CustomInput
            id="HeadlineEN"
            label={t('userslist.password')}
            {...register('password')}
            errors={errors}
          />
          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default AddBackOfficeUser;
