import React from 'react';
import { Card, CardContent, Typography, Box, Fab } from '@mui/material';

import FeatherIcon from 'feather-icons-react';

const Purchases = ({ statistics, name, icon }) => (
  <Card sx={{ height: '80%', background: '#76B8B0' }}>
    <CardContent>
      <Box>
        <Typography
          variant="h3"
          fontWeight="700"
          color="white"
          sx={{
            marginBottom: 2,
          }}
          gutterBottom
        >
          {name}
        </Typography>
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Fab
            size="medium"
            color="white"
            aria-label="add"
            elevation="0"
            sx={{
              boxShadow: 'none',
            }}
          >
            <FeatherIcon icon={icon} />
          </Fab>
        </Box>
      </Box>
      <Typography
        variant="h1"
        fontWeight="900"
        sx={{
          marginBottom: '0',
          marginTop: '20px',
          color: 'white',
        }}
        gutterBottom
      >
        {statistics}
      </Typography>
    </CardContent>
  </Card>
);

export default Purchases;
