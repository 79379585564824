import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const useUserScema = (isEdit = false) => {
  const { t } = useTranslation();
  return yup.object({
    email: yup.string().email(t('errors.email')).required(t('errors.required')),
    password: yup.string().required(t('errors.required')),
  });
};

export default useUserScema;
