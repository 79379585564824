import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Toolbar,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import { useDebounce } from 'use-lodash-debounce';
import CustomTextField from '../forms/custom-elements/CustomTextField';
import EnhancedTableHead from './EnhancedTableHead';
import { useHttpHook } from '../../hooks';
import Spinner from '../../views/spinner/Spinner';

const EnhancedTableToolbar = (props) => {
  const { onChange, searchTerm } = props;
  const { t } = useTranslation();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <CustomTextField
        id="outlined-basic"
        inputProps={{ 'aria-label': 'Search emails' }}
        size="small"
        value={searchTerm}
        variant="outlined"
        onChange={onChange}
        placeholder={t('common.search')}
      />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

function CustomPaginatedTable({
  headCells,
  name,
  size = 'medium',
  children,
  url,
  setEntities,
  _responseKey,
  includeActions,
  ...extraParams
}) {
  const [page, setPage] = useState(0);
  const [limit, setNumberOfRowsPerPage] = useState(10);
  const { sendRequest } = useHttpHook();
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedValue = useDebounce(searchTerm, 1000);
  const [sortDirection, setsortDirection] = useState('desc');
  const onSearchTermChange = (e) => {
    const searchvalue = e.target.value; 
    setSearchTerm(searchvalue);
  };

  const sortDirectionhandleChange = (event) => {
    setsortDirection(event.target.value);
  };

  function fetchEntities() {
    const p = page + 1;
    return sendRequest({
      url,
      params: {
        page: p,
        limit,
        'filter[query]': searchTerm,
        sortDirection,
        ...extraParams,
      },
    });
  }

  const { isLoading, data } = useQuery(
    [name, page, limit, debouncedValue, sortDirection],
    fetchEntities,
  );

  useEffect(() => {
    if (data) {
      setEntities(data.data);
    }
  }, [debouncedValue, data, sortDirection, limit]);

  function handleChangePage(_, currentPage) {
    setPage(currentPage);
  }

  function handleChangeNumberOfRows(event) {
    setNumberOfRowsPerPage(parseInt(event.target.value, 10));
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Card>
      <CardContent>
        <Box>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} paddingTop={2} paddingLeft={2}>
                  <Grid item xs={9}>
                    <EnhancedTableToolbar searchTerm={searchTerm} onChange={onSearchTermChange} />
                  </Grid>

                  <Grid item xs={1.5}>
                    <Select
                      labelId="sortDirection"
                      id="sortDirection"
                      value={sortDirection}
                      label="sortDirection"
                      onChange={sortDirectionhandleChange}
                      sx={{ marginTop: 1, marginLeft: 1 }}
                    >
                      <MenuItem value="asc">asc</MenuItem>
                      <MenuItem value="desc">desc</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Box>

              <Table sx={{ minWidth: 750 }} aria-labelledby={name} size={size}>
                <EnhancedTableHead headCells={headCells} includeActions={includeActions} />
                <TableBody>{children}</TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data?.total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeNumberOfRows}
            />
          </Paper>
        </Box>
      </CardContent>
    </Card>
  );
}

CustomPaginatedTable.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
  extraParams: PropTypes.object,
  responseKey: PropTypes.string.isRequired,
  setEntities: PropTypes.func.isRequired,
  includeActions: PropTypes.bool,
};

export default CustomPaginatedTable;
