import { Box, Card, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import useAddSuccessSchema from './_hooks/useAddSuccessSchema';
import CustomAutoComplete from '../../components/forms/custom-elements/CustomAutoComplete';
import CustomInput from '../../components/forms/custom-elements/CustomInput';
import FileUpload from '../../components/FileUpload';
import { useAuthentication, useCategories } from '../../hooks';
import VideoUpload from '../../components/VideoUpload';

import SubmitButton from '../../components/forms/custom-elements/SubmitButton';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import { SuccessStory } from '../../constants';
import CustomTextEditor from '../../components/forms/custom-elements/CustomTextEditor';
import axios from 'axios';
import ApiConfig from '../../config/api.config';
import i18next from 'i18next';
import useSingleSector from '../../hooks/useSingleSector';
import { Navigate, useNavigate } from 'react-router';

const defaultValues = {
  categories: [],
  status: SuccessStory.InActive,
};

const AddSuccessStory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageTitle = t('successStory.successStory');
  const schema = useAddSuccessSchema();
  const [imageFile, setImageFile] = useState([]);
  const [videoFile, setVideoFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const { tokenApi, isAuthorized } = useAuthentication();

  const alert = useAlert();
  let { categories } = useCategories();
  categories = categories?.data?.map((cat) =>
    i18next.language === 'en'
      ? {
          label: cat.name.en,
          value: cat.id,
        }
      : {
          label: cat.name.ar,
          value: cat.id,
        },
  );
  let { sectors } = useSingleSector();
  sectors = sectors?.data?.map((cat) =>
    i18next.language === 'en'
      ? {
          label: cat?.title?.en,
          value: cat?.id,
        }
      : {
          label: cat?.title?.ar,
          value: cat?.id,
        },
  );
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const breadcrumbItems = [
    {
      to: '/',
      title: t('home.homepage'),
    },
    {
      to: '/successStory',
      title: t('successStory.name'),
    },
    {
      title: pageTitle,
    },
  ];

  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const formData = new FormData();

      if (imageFile.length >= 1) {
        imageFile.forEach((img, index) => {
          formData.append(`image_array[${index}]`, img);
        });
      }

      if (videoFile.length >= 1) {
        videoFile.forEach((vid, index) => {
          formData.append(`video_array[${index}]`, vid);
        });
      }
      const category = JSON.stringify(form.categories.map((cat) => cat.value));

      if (category !== '[]') {
        formData.append('categories', category);
      }
      const field = JSON.stringify([form.sectors]);
      formData.append('field', field);

      const headline = JSON.stringify({
        en: form.HeadlineEN,
        ar: form.HeadlineAR,
      });
      const description = JSON.stringify({
        en: form.DescriptionEN,
        ar: form.DescriptionAR,
      });
      formData.append('head_line', headline);
      formData.append('description', description);
      formData.append('status', form.status);
      formData.append('main_image', form.image);

      await axios({
        method: 'POST',
        url: `${ApiConfig.baseURL}/api/stories `,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + tokenApi },
      });
      alert.success(t('common.addedSuccessfully'));
      setLoading(false);
      navigate('/successStory');
    } catch (err) {
      Object.values(err?.response?.data?.errors)?.map((i) => alert.error(i));
      setLoading(false);
    }
  };
  if (!tokenApi) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <PageContainer title={pageTitle}>
      <Breadcrumb items={breadcrumbItems} title={pageTitle} />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Arabic Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.arabicSection')}
          </Typography>
          <CustomInput
            id="HeadlineAR"
            label={t('successStory.headline')}
            {...register('HeadlineAR')}
            errors={errors}
          />
          <CustomTextEditor
            id="DescriptionAR"
            label={t('successStory.description')}
            control={control}
            errors={errors}
            defaultValue=""
          />
          {/* English Translation */}
          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.englishSection')}
          </Typography>
          <CustomInput
            id="HeadlineEN"
            label={t('successStory.headline')}
            {...register('HeadlineEN')}
            errors={errors}
          />
          <CustomTextEditor
            defaultValue=""
            id="DescriptionEN"
            label={t('successStory.description')}
            control={control}
            errors={errors}
          />
          {/* General Translation */}

          <Typography sx={{ marginTop: 2 }} variant="h2">
            {t('common.generalSection')}
          </Typography>
          <CustomInput
            id="image"
            label={t('common.mainImage')}
            type="file"
            errors={errors}
            accept="image/png,image/jpeg,image/jpg"
            {...register('image')}
          />
          <CustomFormLabel>{t('common.gallery')}</CustomFormLabel>
          <FileUpload setImageFile={setImageFile} imageFile={imageFile} />
          <CustomFormLabel>{t('common.video')}</CustomFormLabel>

          <VideoUpload setVideoFile={setVideoFile} videoFile={videoFile} />
          <CustomAutoComplete
            control={control}
            defaultValue={[]}
            errors={errors}
            id="categories"
            label={t('common.categories')}
            multiple
            options={categories || ''}
          />
          <CustomSelect id="sectors" label={t('common.sectors')} control={control} errors={errors}>
            {sectors?.map((item) => (
              <MenuItem key={item?.value} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </CustomSelect>

          <CustomSelect id="status" label={t('common.status')} control={control}>
            {Object.keys(SuccessStory).map((key) => (
              <MenuItem key={key} value={SuccessStory[key]}>
                {key}
              </MenuItem>
            ))}
          </CustomSelect>

          <Box sx={{ mt: 2 }}>
            <SubmitButton isLoading={loading} />
          </Box>
        </form>
      </Card>
    </PageContainer>
  );
};

export default AddSuccessStory;
