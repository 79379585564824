import React, { useEffect, useState } from 'react';
import { useHttpHook } from '../../../hooks';

const useSingleType = (typeId) => {
  const { sendRequest, isLoading } = useHttpHook();
  const [contentTypes, setContentTypes] = useState(null);
  useEffect(() => {
    const fetchContentTypes = () =>
      sendRequest({
        url: `api/contents/groups/${typeId}`,
      });

    (async () => {
      const response = await fetchContentTypes();
      if (response?.tr) {
        const { id, ...rest } = response?.tr;
        Object.assign(response, rest);
      }
      setContentTypes(response);
    })();
  }, []);

  return {
    isLoading,
    contentTypes,
  };
};

export default useSingleType;
